import { push } from 'connected-react-router';
import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { toastConfig } from 'utils/config';
import { ROUTES } from 'configs/routes';

import { onboardingWatcher, updateProfileSuccess, updateProfileFailed } from './onboardingSlice';

function* signupEffectSaga(action) {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.patch(API_ROUTES.ONBOARD, action.payload, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(updateProfileSuccess(response.data));
    toast.success('You have registered successfully. Post a job to kickstart your hiring', toastConfig);
    localStorage.setItem('onboarding', true);

    yield put(push(ROUTES.DASHBOARD));
  } else {
    yield put(updateProfileFailed(response.data));
  }
}

function* registrationSaga() {
  yield takeLatest(onboardingWatcher, signupEffectSaga);
}

export default registrationSaga;
