/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import classnames from 'classnames';
import { FaTachometerAlt, FaSignOutAlt } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { Nav } from 'react-bootstrap';

import { LoginModal, RegisterModal, ForgotModal, ForgotConfirm } from 'components/Modal';
import './styles.css';
import logo from 'assets/images/logo.png';
import { t } from 'configs/i18n';
import { ButtonMenu, StyledNavbar as Navbar, CenterHeader } from './styled';

import RenderAvatar from '../../Common/RenderAvatar';
import DropDown from '../DropDown';
// const FullAuthModal = lazy(() => import('../../../components/Modals/FullAuthModals'));

const styles = (themes) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: themes.spacing.unit * 4,
  },
  menuItem: {},
  primary: {},
  icon: {
    width: 20,
    height: 20,
    color: '#eb5b49',
  },
  logo: {
    width: 100,
    '@media (min-width: 770px)': {
      paddingLeft: 16,
    },
  },
  typography_1: {
    padding: '10px 15px 5px',
    fontSize: 14,
    cursor: 'pointer',
  },
  typography_2: {
    padding: '5px 15px 10px',
    fontSize: 14,
    cursor: 'pointer',
  },
});

const Topbar = (props) => {
  const {
    classes,
    isLogin,
    profile,
    detail,
    target,
    status,
    isFixed,
    handleRedirect,
    profileWatcher,
    logout,
    push,
  } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
  const [isOpenForgotModal, setIsOpenForgotModal] = useState(false);
  const [isOpenForgotConfirm, setIsOpenForgotConfirm] = useState(false);

  const handleOpenLogin = () => {
    setIsOpenLoginModal(true);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenRegister = () => {
    setIsOpenRegisterModal(true);
    setIsOpenLoginModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgot = () => {
    setIsOpenForgotModal(true);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgotConfirm = () => {
    setIsOpenForgotConfirm(true);
    setIsOpenForgotModal(false);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
  };

  const closeAllModal = () => {
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  useEffect(() => {
    if (isLogin) {
      profileWatcher();
    }
  }, [profileWatcher, isLogin]);

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    logout();
    push('/');
  };

  const goToBlogPage = (path) => () => {
    window.location.href = `${process.env.REACT_APP_BLOG_HOST}${path}`;
  };

  return (
    <>
      <Navbar
        bgcolor={target === 'checkout' || target === 'payment' ? '#efefef' : 'none'}
        className={classnames({
          nonpadding: target === 'checkout' || target === 'payment',
        })}
        expand="lg"
        isfixed={isFixed ? 1 : 0}
      >
        <Navbar.Brand
          onClick={() => handleRedirect('/')}
          className={classnames({
            nonpadding: target === 'checkout' || target === 'payment',
          })}
          style={{ cursor: 'pointer' }}
        >
          <img src={logo} alt="9cv9" className={classes.logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {target === 'checkout' ? (
          <CenterHeader>
            <LockIcon style={{ marginRight: '10px', fontSize: '1.2em' }} />
            {t('secure_checkout')}
          </CenterHeader>
        ) : null}
        {target === 'payment' ? <CenterHeader>{status}</CenterHeader> : null}
        <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'flex-end' }}>
          <div>
            <Nav className="mr-auto center-align" style={{ alignItems: 'center' }}>
              <Nav.Link style={{ color: '#3c3c3c' }} onClick={() => handleRedirect('/terms&condition')}>
                {t('terms')}
              </Nav.Link>
              <Nav.Link style={{ color: '#3c3c3c' }} onClick={goToBlogPage('/category/partners/')}>
                {t('resources')}
              </Nav.Link>
              {!window.location.href.includes('9cv9.vn') && !window.location.href.includes('9cv9jobs.id') && (
                <DropDown />
              )}

              {!isLogin && (
                <div className="button-menu-group">
                  <ButtonMenu style={{ outline: 'none' }} backgroundColor="#283583" onClick={handleOpenRegister}>
                    {t('register')}
                  </ButtonMenu>
                  <ButtonMenu style={{ outline: 'none' }} backgroundColor="#EB5B49" onClick={handleOpenLogin}>
                    {t('login')}
                  </ButtonMenu>
                </div>
              )}
              {isLogin && (
                <Nav.Link>
                  <Button
                    buttonRef={(node) => {
                      setAnchorEl(node);
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={() => setOpen(!open)}
                    style={{ outline: 'none' }}
                  >
                    <RenderAvatar width={40} height={40} classes={classes} profile={profile} detail={detail} />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    style={{ zIndex: 10000 }}
                    transition
                    // disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList style={{ paddingLeft: 20, paddingRight: 20 }}>
                              <>
                                <MenuItem className={classes.menuItem} onClick={() => handleRedirect('/dashboard')}>
                                  <ListItemIcon className={classes.icon}>
                                    <FaTachometerAlt
                                      style={{
                                        width: 20,
                                        height: 20,
                                        color: '#eb5b49',
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className="pl-0" inset primary={t('main')} />
                                </MenuItem>
                                <MenuItem className={classes.menuItem} onClick={() => handleRedirect('/settings')}>
                                  <ListItemIcon className={classes.icon}>
                                    <MdSettings
                                      style={{
                                        width: 20,
                                        height: 20,
                                        color: '#eb5b49',
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className="pl-0" inset primary={t('setting')} />
                                </MenuItem>
                                <MenuItem className={classes.menuItem} onClick={handleLogout}>
                                  <ListItemIcon className={classes.icon}>
                                    <FaSignOutAlt
                                      style={{
                                        width: 20,
                                        height: 20,
                                        color: '#eb5b49',
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className="pl-0" inset primary={t('sign-out')} />
                                </MenuItem>
                              </>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Nav.Link>
              )}
            </Nav>
          </div>
        </Navbar.Collapse>
        <RegisterModal open={isOpenRegisterModal} handleOpenLoginModal={handleOpenLogin} handleClose={closeAllModal} />
        <LoginModal
          open={isOpenLoginModal}
          handleOpenRegisterModal={handleOpenRegister}
          handleOpenForgotModal={handleOpenForgot}
          handleClose={closeAllModal}
        />
        <ForgotModal
          open={isOpenForgotModal}
          handleOpenLoginModal={handleOpenLogin}
          handleOpenRegisterModal={handleOpenRegister}
          handleOpenForgotConfirmModal={handleOpenForgotConfirm}
          handleClose={closeAllModal}
        />
        <ForgotConfirm open={isOpenForgotConfirm} handleClose={closeAllModal} />
        {/* <FullAuthModal /> */}
      </Navbar>
    </>
  );
};

Topbar.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  requestProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  profile: PropTypes.any.isRequired,
  detail: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  target: PropTypes.string,
  status: PropTypes.any,
  isFixed: PropTypes.bool,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
};

export default withStyles(styles)(Topbar);
