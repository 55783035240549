// libs
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactPhoneInput from 'react-phone-input-mui';
import 'react-phone-input-mui/dist/style.css';
import { Button, TextField, Paper, MenuItem } from '@material-ui/core';
import PlacesAutocomplete from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import { MdClose, MdPlace } from 'react-icons/md';
import { Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'configs/i18n';

import SnipperLoading from 'components/Common/SnipperLoading';
import OnboardingLayout from 'components/Onboarding/Layout';
import OnboardingInput from './Commons/OnboardingInput';

// styles
import {
  ErrorMessage,
  LogoContainer,
  CloseContainer,
  AvatarImage,
} from './Commons/styled';

const styles = theme => ({
  root: {
    width: '90%',
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  descriptionWrap: {
    padding: '15px 15px 15px 20px',
    boxShadow: '2px 2px 20px rgba(0, 0, 0, 0.1)',
    marginLeft: -20,
  },
  rocketIcon: {
    width: 40,
    height: 40,
    margin: 'auto 0',
  },
  mobileInput: {
    height: 'unset',
    marginTop: 5,
  },
});

class BasicInfo extends Component {
  static propTypes = {
    state: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      mobile_number: '',
      avatar: null,
      avatarString: '',
      skype: '',
      address: '',
      total_experience: null,
      changed: false,
      error_first_name: false,
      error_last_name: false,
      error_mobile_number: false,
      error_address: false,
    };
  }

  componentDidMount() {
    const { goDashboard } = this.props;
    const firstLogin = localStorage.getItem('onboarding');
    if (firstLogin !== 'false') {
      goDashboard();
    }
  }

  handleChangeAddress = address => {
    this.setState({ address });
  };

  handleSelectAddress = address => {
    this.setState({ address, hasSelect: true });
  };

  handleChange = event => {
    const { name, value } = event.target;
    if (name === 'total_experience') {
      this.setState({ [name]: parseInt(value), changed: true });
    } else this.setState({ [name]: value, changed: true });
    if (name) {
      switch (name) {
        case 'first_name':
          this.setState({ error_first_name: false });
          break;
        case 'last_name':
          this.setState({ error_last_name: false });
          break;
        case 'mobile_number':
          this.setState({ error_mobile_number: false });
          break;
        default:
          break;
      }
    }
  };

  handleMoveNext = () => {
    const { onboardingWatcher } = this.props;
    const { first_name, last_name, mobile_number, address, skype, avatarString } = this.state;
    const errors = {
      error_first_name: false,
      error_last_name: false,
      error_mobile_number: false,
      error_address: false,
    };

    if (
      !first_name.trim() ||
      !last_name.trim() ||
      !mobile_number.trim() ||
      !address.trim() ||
      first_name.length > 30
    ) {
      if (!first_name.trim()) errors.error_first_name = true;
      if (!last_name.trim()) errors.error_last_name = true;
      if (!mobile_number.trim()) errors.error_mobile_number = true;
      if (!address.trim()) errors.error_address = true;
      if (first_name.length > 30) errors.error_first_name = 'This field should no more than 30 character';
      this.setState(errors);
    } else {
      this.setState(errors, () => {
        const data = {
          first_name,
          last_name,
          mobile_number,
          skype,
          avatar: avatarString,
          onboarding: true,
          address,
        };
        onboardingWatcher(data);
      });
    }
  };

  handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        avatar: file,
        avatarString: reader.result,
        changed: true,
      });
    };

    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  resetAvatarImage = () => {
    this.setState({ avatar: null, avatarString: '' });
  };

  renderLogo = classes => {
    const { avatarString } = this.state;
    if (avatarString === '') {
      return (
        <div style={{ textAlign: 'left' }}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.handleImageChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span" className={classes.button}>
              {t('onboarding.basic_info.upload')}
            </Button>
          </label>
        </div>
      );
    }

    return (
      <LogoContainer>
        <AvatarImage src={avatarString} />
        <CloseContainer>
          <MdClose style={{ fontSize: 28 }} onClick={this.resetAvatarImage} />
        </CloseContainer>
      </LogoContainer>
    );
  };

  render() {
    const { classes, loading, } = this.props;
    const {
      first_name,
      error_first_name,
      error_last_name,
      error_mobile_number,
      last_name,
      mobile_number,
      skype,
      address,
      error_address,
    } = this.state;

    return (
      <OnboardingLayout title={t('onboarding.basic_info.title')}>
        <SnipperLoading
          loading={loading}
          text={t('loading')}
          cover
        />
        <Container>
          <Row>
            {/* first_name */}
            <OnboardingInput
              label={`${t('onboarding.basic_info.first_name')}*: `}
              value={first_name}
              name="first_name"
              placeholder="Gilbert"
              helperText={
                error_first_name && error_first_name === true ? t('onboarding.required_field') : error_first_name
              }
              error={error_first_name}
              onChange={this.handleChange}
            />

            {/* last_name */}
            <OnboardingInput
              label={`${t('onboarding.basic_info.last_name')}*: `}
              value={last_name}
              name="last_name"
              placeholder="Gilbert"
              helperText={error_last_name && t('onboarding.required_field')}
              error={error_last_name}
              onChange={this.handleChange}
            />

            {/* mobile_number */}
            <OnboardingInput
              label={`${t('onboarding.basic_info.mobile_number')}*: `}
              labelClassName="flex justify-end items-center"
              inputComponent={
                <>
                  <ReactPhoneInput
                    defaultCountry="sg"
                    value={mobile_number}
                    component={TextField}
                    inputExtraProps={{
                      name: 'mobile_number',
                      onChange: this.handleChange,
                    }}
                    inputClass={classes.mobileInput}
                    containerStyle={{ marginTop: '9px' }}
                  />
                  {error_mobile_number && <ErrorMessage>{t('onboarding.required_field')}</ErrorMessage>}
                </>
              }
            />

            {/* Skype ID */}
            <OnboardingInput
              label="Skype ID: "
              value={skype}
              name="skype"
              placeholder="Gilbert"
              onChange={this.handleChange}
            />

          </Row>
          <Row>
            {/* profile_picture */}
            <OnboardingInput
              label={`${t('onboarding.basic_info.profile_picture')}: `}
              subLabel={`${t('onboarding.basic_info.profile_picture_hint')}: 360*360px`}
              inputComponent={this.renderLogo(classes)}
            />

            {/* address */}
            <OnboardingInput
              label={`${t('onboarding.company_info.address')}*: `}
              inputComponent={
                <PlacesAutocomplete value={address} onChange={this.handleChangeAddress}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <TextField
                        fullWidth
                        value={address}
                        helperText={error_address && !address && t('onboarding.required_field')}
                        error={error_address && !address}
                        {...getInputProps({
                          placeholder: t('onboarding.company_info.type_in_address'),
                          className: 'location-search-input',
                        })}
                        inputProps={{ style: { textAlign: 'left' } }}
                      />
                      <Paper className={classes.paper} square>
                        {loading && <div>{t('onboarding.company_info.loading')}</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <MenuItem component="div">
                                <div>
                                  <MdPlace style={{ fontSize: 30, paddingRight: 10 }} />
                                </div>
                                <div>{suggestion.description}</div>
                              </MenuItem>
                            </div>
                          );
                        })}
                      </Paper>
                    </div>
                  )}
                </PlacesAutocomplete>
              }
            />
          </Row>
        </Container>

        <div className="text-center mt-60 mb-60">
          <Button
            onClick={this.handleMoveNext}
            variant="contained"
            color="primary"
            style={{
              borderRadius: 40,
              width: 100,
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {t('onboarding.next')}
          </Button>
        </div>
      </OnboardingLayout>
    );
  }
}

export default withTranslation()(withStyles(styles)(BasicInfo));
