import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SnipperImage from 'assets/images/SnipperLoading.png';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 110;
  @-webkit-keyframes animationLoading {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animationLoading {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  img {
    -webkit-animation: animationLoading 1s alternate infinite ease-in;
    animation: animationLoading 1s alternate infinite ease-in;
  }
  ${({ cover }) =>
    cover &&
    `
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255,255,255,0.8);
  `};

  ${({ css }) => css};
`;

const Image = styled.img`
  width: 84px;
  height: 84px;
`;

const Text = styled.div`
  margin-top: 22px;
  ${({ textCss }) => textCss};
`;

const SnipperLoading = props => {
  const { loading, cover, css, text, textCss } = props;

  useEffect(() => {
    if (loading) {
      document.body.setAttribute('style', 'height: 100vh; overflow: hidden;');
    }
    if (!loading) {
      document.body.removeAttribute('style');
    }
    return () => document.body.removeAttribute('style');
  }, [loading, cover]);

  return (
    <>
      {loading && (
        <Wrapper id="snipper_loading" cover={cover} css={css}>
          <Image src={SnipperImage} alt="loading" />
          <Text textCss={textCss}>{text}</Text>
        </Wrapper>
      )}
    </>
  );
};

SnipperLoading.defaultProps = {
  text: null,
  textCss: '',
  css: '',
  cover: false,
};

SnipperLoading.propTypes = {
  text: PropTypes.string,
  textCss: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  css: PropTypes.string,
  cover: PropTypes.bool,
};

SnipperLoading.propTypes = {
  text: PropTypes.string,
};

export default SnipperLoading;
