import React from 'react';
import PropTypes from 'prop-types';
import { Modal, DialogContent } from '@material-ui/core';

const CommonModal = props => {
  const { isOpenModal, onCloseModal, children } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpenModal}
      onClose={onCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
      }}
    >
      <DialogContent style={{ flex: 'inherit' }}>{children}</DialogContent>
    </Modal>
  );
};

export default CommonModal;

CommonModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  children: PropTypes.any,
};
