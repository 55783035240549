import styled from 'styled-components';

export const stylesFormEdit = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    outline: 'none',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginBottom: 3,
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '47%',
    },
  },
  dateField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '47%',
    },
  },
  inputSize: {
    height: 6,
    fontSize: '14px',
  },
  inputSizeNumber: {
    height: 42,
    fontSize: '14px',
    textAlign: 'center',
  },
  inputSizePosition: {
    height: 6,
    fontSize: '14px',
    minHeight: 6,
    lineHeight: '6px',
    textAlign: 'center',
  },
  textFieldFull: {
    marginBottom: 1,
  },
  modalHeader: {
    position: 'relative',
    paddingBottom: 8,
  },
  btnClose: {
    position: 'absolute',
    top: -15,
    right: 0,
  },
  menu: {
    width: 200,
  },
  datetime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  conts: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  grade: {
    marginBottom: 15,
  },
  datetimeItem: {
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '47%',
    },
    marginTop: 16,
    marginBottom: 3,
  },
  modalFooter: {
    justifyContent: 'center',
    paddingBottom: 20,
  },
  warning: {
    marginBottom: 1,
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '47%',
    },
    fontSize: '14px',
    color: 'red',
  },
  phone: {
    marginBottom: 1,
    width: '53%',
    [theme.breakpoints.up('md')]: {
      width: '62%',
      marginLeft: '-18px',
    },
  },
  area: {
    marginBottom: 1,
    width: '45%',
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
  },
});

export const ModalContainer = styled.div`
  width: 700px;
  max-height: 600px;
  overflow: auto;
  max-width: 75vw;
`;

export const ModalTitle = styled.h1`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -15px;
  margin-top: 15px;
`;
export const ContainerModalLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;

  h6 {
    color: #fff;
    margin-left: 10px;
  }
`;

export const GroupSection = styled.div`
  display: flex;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`;

export const FieldSection = styled.div`
  display: flex;
  flex: 0 0 50%;
  margin: 15px 0;
  margin-right: -10px;
  margin-left: -60px @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`;

export const Label = styled.div`
  width: 200px;
  @media only screen and (max-width: 770px) {
    width: auto;
    text-align: center;
  }
  text-align: right;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  padding-top: 5px;
`;

export const InputContainer = styled.div`
  width: 180px;
  text-align: left;
  padding-left: 10px;
  @media only screen and (max-width: 770px) {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
`;

export const CancelButton = styled.div`
  height: 27px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #929699;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
`;

export const ResultFindRowCompany = styled.div`
  line-height: 38px;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: #dddd;
  }
`;
