import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: [],
  isLoading: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    onboardingWatcher: (state) => {
      state.isLoading = true;
    },
    updateProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    },
    updateProfileFailed: (state, action) => {
      state.isLoading = false;
      state.profile = [];
    },
  },
});

export const { onboardingWatcher, updateProfileSuccess, updateProfileFailed } = registrationSlice.actions;

export default registrationSlice.reducer;
