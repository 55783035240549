export const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
};

export function getCountryCode() {
  const name = [
    '+374',
    '+60',
    '+62',
    '+63',
    '+65',
    '+66',
    '+673',
    '+7',
    '+81',
    '+82',
    '+84',
    '+850',
    '+852',
    '+853',
    '+855',
    '+856',
    '+86',
    '+880',
    '+886',
    '+90',
    '+91',
    '+92',
    '+93',
    '+94',
    '+95',
    '+960',
    '+961',
    '+962',
    '+963',
    '+964',
    '+965',
    '+966',
    '+967',
    '+968',
    '+971',
    '+972',
    '+973',
    '+974',
    '+975',
    '+976',
    '+977',
    '+98',
    '+992',
    '+993',
    '+994',
    '+995',
    '+996',
    '+998',
  ];
  return name;
}
