import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  withStyles,
  Link,
} from '@material-ui/core';
// import { MdNotificationsNone } from 'react-icons/md';
import { FaEllipsisV, FaBars } from 'react-icons/fa';
import _get from 'lodash/get';
import MenuIcon from '@material-ui/icons/Menu';
import { SnipperLoading, RenderAvatar } from 'components/Common';
import {
  styles,
  CompanyName,
  LeftSection,
  Name,
  RightSection,
  Section,
  LayoutWrapper,
  // notificationBox,
  HiddenBreakpoint,
} from './styled';
import { TextLink } from 'components/Common/CenterLineText/styled';
// import NotificationItem from 'components/Notifications/NotificationItem';
import DropDownLanguage from 'components//LandingPage/DropDown/index';
import { MENU_PARTNERS } from './nazBar';
import { specialScreen } from './constants';
import logo from './logo.png';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  componentDidMount() {
    const { profileWatcher } = this.props;
    profileWatcher();
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     profile = {},
  //     requestNotifications,
  //   } = this.props;
  //   const { profile: prevProfile = {} } = prevProps;
  //   if (profile.type !== prevProfile.type) {
  //     requestNotifications(profile.type);
  //   }
  // }

  handleLogout = () => {
    const { logout } = this.props;
    localStorage.clear();
    logout();
    this.handleRedirect('/')();
  };

  handleReadNotifications = (notification, readAll) => () => {
    const {
      profile,
      readNotification,
      changeApplicationTab,
      requestNotifications,
      updateSelectedTab,
    } = this.props;
    const id = notification ? notification.id : null;
    if (id === null) {
      const type = profile.type === 1 ? 'candidate' : 'employer';
      readNotification([id], readAll, type);
      requestNotifications(type);
    } else if (profile.type === 1) {
      readNotification([notification.id], readAll, 'candidate');
      if (notification.type === 15) {
        return;
      }
      if (notification.type === 16) {
        return;
      }
      let tab = notification.type + 1; // notification.type = 1,2
      if ([3, 4, 5, 6].includes(notification.type)) {
        tab = 4;
      }
      if (notification.type === 7) {
        tab = 5;
      }
      if (notification.type === 8) {
        tab = 6;
      }
      if (notification.type === 14) {
        tab = 7;
      }
      changeApplicationTab(tab);
      this.handleRedirect('/applications')();
    } else {
      readNotification([notification.id], readAll, 'employer');

      if ([0, 1, 5, 14, 15, 16, 17, 18, 19].includes(notification.type) && notification.candidate_id) {
        this.handleRedirect(`/candidate/detail/${notification.candidate_id}`)();
      }

      if ([2, 3, 4, 6, 7].includes(notification.type) && notification.job_id) {
        this.handleRedirect(`/jobs/detail/${notification.job_id}`)();
      }

      if ([8, 9, 10, 11].includes(notification.type)) {
        updateSelectedTab(1);
        this.handleRedirect('/settings')();
      }
    }
    requestNotifications(profile.type);
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleRedirect = url => () => {
    const { push } = this.props;
    push(url);
  };

  render() {
    const {
      classes,
      theme,
      children,
      title,
      active,
      customComponent,
      type,
      profile,
      pageName,
      container,
      noAppbar,
      // notifications,
      t,
      isCollapsedMenuBar,
      collapseMenuBar,
    } = this.props;
    const profileFirstName = _get(profile, 'first_name', '');
    const profileLastName = _get(profile, 'last_name', '');

    if (profile && profile.type === '') {
      return (
        <SnipperLoading
          loading={profile && profile.type === ''}
          text={t('dashboard.after_login')}
          css="background-color: #fff;"
        />
      );
    }
    const { mobileOpen } = this.state;
    // let notificationsCountNew = 0;
    // const allNotifications = _get(notifications, 'results');
    // const isAnyNotifications = !_isEmpty(allNotifications);
    // if (Array.isArray(allNotifications)) {
    //   notificationsCountNew = allNotifications.filter(item => item.status === 0).length;
    // }

    const isCandidate = type === 1;
    const titleJob = t('job_list.title');
    const subtitleJob = t('job_list.subtitle');

    let userName = `${profileFirstName} ${profileLastName}` || 'Noname';
    if (!profileFirstName) {
      userName = 'Noname';
    }

    const drawer = (
      <div>
        <div className={`${classes.drawerHeader} ${isCollapsedMenuBar ? 'h-40' : ''}`}>
          <div className={classes.collapseIcon} onClick={() => collapseMenuBar()}>
            {isCollapsedMenuBar ? <FaBars /> : <FaEllipsisV />}
          </div>
          {!isCollapsedMenuBar && (
            <Link href="/" className="ml-15">
              <img src={logo} alt="9cv9" width="100px" />
            </Link>
          )}
        </div>
        <Divider className="my-0 ml-10 mr-10" />
        <Section>
          <RenderAvatar
            width={isCollapsedMenuBar ? 50 : 75}
            height={isCollapsedMenuBar ? 50 : 75}
            profile={profile}
            classes={classes}
          />
          <Name
            inputFontSize={
              isCollapsedMenuBar
                ? '14px'
                : (profileFirstName && profileFirstName.length) + (profileLastName && profileLastName.length) > 20 &&
                '18px'
            }
          >
            {userName}
          </Name>
          {!isCollapsedMenuBar && <CompanyName>{t('partner')}</CompanyName>}
        </Section>
        <Divider className="my-0 ml-10 mr-10" />
        <Section>
          <List className="w-full">
            {MENU_PARTNERS(t).map(item => {
              const isShow = !item.isDisplay;
              return (
                <div key={item.key}>
                  <ListItem
                    button
                    className={[
                      active === item.active ? 'border-r-blue' : '',
                      isCollapsedMenuBar || item.active === 'companies' ? 'mb-4' : 'mb-12',
                      isShow ? 'hidden' : '',
                      'no-underline',
                    ].join(' ')}
                    onClick={item.isSignOut ? this.handleLogout : this.handleRedirect(item.url)}
                  >
                    <ListItemIcon className={isCollapsedMenuBar ? classes.collapsedMenuIcons : ''}>
                      <item.icon className={`fs-20 ${active === item.active ? 'c:blue' : ''}`} />
                    </ListItemIcon>
                    {!isCollapsedMenuBar && (
                      <ListItemText
                        primary={item.key}
                        className={`p-0 ${active === item.active ? 'c:blue' : ''}`}
                      />
                    )}
                  </ListItem>
                </div>
              );
            })}
          </List>
          <div className="mx-auto -mt-14 mb-20">
            {!window.location.href.includes('9cv9.vn') &&
              !window.location.href.includes('9cv9jobs.id') &&
              mobileOpen && <DropDownLanguage />}
          </div>
        </Section>
      </div>
    );

    // const menu = (
    //   <Paper>
    //     <div className="flex w-350 pt-10 pl-12 pb-10 pr-12">
    //       <div className="flex-1">{t('dashboard.notification_list_title')}</div>
    //       <a href=" #" onClick={isAnyNotifications ? this.handleReadNotifications(null, true) : null}>
    //         {t('dashboard.mark_as_read')}
    //       </a>
    //     </div>
    //     {isAnyNotifications &&
    //       allNotifications.map(
    //         (item, index) =>
    //           index < 5 && <NotificationItem item={item} key={item.id} onclick={this.handleReadNotifications} />,
    //       )}
    //     <Button
    //       size="small"
    //       className="text-center normal-case bg-c:#f6f6f6 c:#7a82ff"
    //       onClick={this.handleRedirect('/notifications')}
    //       fullWidth
    //     >
    //       {t('dashboard.show_all')}
    //     </Button>
    //   </Paper>
    // );

    return (
      <div className={classes.root}>
        <CssBaseline />
        {noAppbar ? (
          <AppBar
            position="fixed"
            className={`bg-c:white border-solid border-b-1 border:white box-shadow-none ${classes.appBar} ${isCollapsedMenuBar ? 'collapse-menu-bar-app-bar' : ''
              }`}
          >
            <Toolbar className="justify-between border-none">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <MediaQuery query="(min-width: 700px)">
                <LeftSection>
                  {active !== 'candidates' && (
                    <Typography variant="h6" color="inherit" noWrap className="fs-20">
                      <TextLink onClick={() => window.history.back()}>
                        <div className="-mt-2 mr-4">&#60;</div>
                        <div>{t('back')}</div>
                      </TextLink>
                    </Typography>
                  )}
                </LeftSection>
              </MediaQuery>
              {customComponent}
              <RightSection>
                <HiddenBreakpoint>
                  <DropDownLanguage />
                </HiddenBreakpoint>
                {/* <Dropdown
                  overlayClassName="notification-dropdown"
                  overlay={menu}
                  overlayStyle={{ ...notificationBox }}
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <IconButton>
                    <Badge count={notificationsCountNew} overflowCount={9} className="absolute -top-25 -right-40" />
                    <MdNotificationsNone className="w-30 h-30" />
                  </IconButton>
                </Dropdown> */}
              </RightSection>
            </Toolbar>
          </AppBar>
        ) : (
            <AppBar
              position="fixed"
              className={`bg-c:white box-shadow-none z-1000 ${classes.appBar} ${isCollapsedMenuBar ? 'collapse-menu-bar-app-bar' : ''
                }`}
            >
              <Toolbar className={`justify-between ${classes.toolBar}`}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <MediaQuery query="(min-width: 700px)">
                  <LeftSection>
                    <Typography variant="h6" color="inherit" noWrap className="fs-20">
                      {type === 0 && (
                        <MediaQuery minWidth={992}>
                          <div className="float-left">{title}</div>
                        </MediaQuery>
                      )}
                      {isCandidate && title === titleJob && subtitleJob}
                      {isCandidate && title !== titleJob && <div>{title}</div>}
                      {pageName === 'Profile' && (
                        <TextLink>
                          <h5>&nbsp;</h5>
                        </TextLink>
                      )}
                      {pageName === 'job-board' && (
                        <TextLink>
                          <h5 onClick={() => window.history.back()}>{t('posting_success.job_board.go_back')}</h5>
                        </TextLink>
                      )}
                    </Typography>
                  </LeftSection>
                </MediaQuery>
                {customComponent}
                <RightSection noPadding>
                  <HiddenBreakpoint>
                    <DropDownLanguage />
                  </HiddenBreakpoint>
                  {/* <Dropdown
                    overlayClassName="notification-dropdown"
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomRight"
                    overlayStyle={{ ...notificationBox }}
                    align={{ overflow: { adjustY: false } }}
                  >
                    <IconButton>
                      <Badge count={notificationsCountNew} overflowCount={9} className="absolute -top-25 -right-40" />
                      <MdNotificationsNone className="w-30 h-30" />
                    </IconButton>
                  </Dropdown> */}
                </RightSection>
              </Toolbar>
            </AppBar>
          )}
        <nav className={`scrollbar-5#e2e2e2 ${classes.drawer} ${isCollapsedMenuBar && 'collapse-menu-bar'}`}>
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{ paper: `${classes.drawerPaper} ${isCollapsedMenuBar && 'collapse-menu-bar'}` }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: `${classes.drawerPaper} ${isCollapsedMenuBar && 'collapse-menu-bar'}` }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <SnipperLoading
          loading={false}
          text={t('loading')}
          cover
          css="background-color: rgba(255,255,255,0.8); z-index: 2201"
        />
        <LayoutWrapper isCollapsedMenuBar={isCollapsedMenuBar}>
          <MediaQuery query="(max-width: 699px)">
            <main className={`p-0 ${classes.content}`}>
              <div className={`min-h-64 ${classes.toolbar}`} />
              <div className={specialScreen.includes(title) && !this.props.application ? 'pt-24' : 'pt-0'}>
                {children}
              </div>
            </main>
          </MediaQuery>
          <MediaQuery query="(min-width: 700px)">
            <main className={`p-0 ${classes.content}`}>
              <div className={`min-h-64 ${classes.toolbar}`} />
              <div className={specialScreen.includes(title) ? 'pt-7' : 'pt-0'}>
                {children}
              </div>
            </main>
          </MediaQuery>
        </LayoutWrapper>
      </div>
    );
  }
}

DashboardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  active: PropTypes.any,
  customComponent: PropTypes.any,
  type: PropTypes.number,
  profile: PropTypes.object,
  pageName: PropTypes.string,
  requestProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  container: PropTypes.any,
  noAppbar: PropTypes.any,
  clearData: PropTypes.func.isRequired,
  notifications: PropTypes.any.isRequired,
  requestNotifications: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  updateSelectedTab: PropTypes.func.isRequired,
  changeApplicationTab: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
  profileWatcher: PropTypes.func.isRequired,
  isCollapsedMenuBar: PropTypes.any.isRequired,
  collapseMenuBar: PropTypes.func.isRequired,
};

DashboardLayout.defaultProps = {
  profile: {},
};

export default withStyles(styles, { withTheme: true })(DashboardLayout);
