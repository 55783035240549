import { FaTachometerAlt, FaSignOutAlt } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';

export const MENU_PARTNERS = t => [
  {
    key: t('main'),
    active: 'Dashboard',
    icon: FaTachometerAlt,
    url: '/dashboard',
    isDisplay: true,
  },
  {
    key: t('setting'),
    active: 'Settings',
    icon: MdSettings,
    url: '/settings',
    isDisplay: true,
  },
  {
    key: t('sign-out'),
    active: 'signout',
    icon: FaSignOutAlt,
    url: '/',
    isDisplay: true,
    isSignOut: true,
  },
];
