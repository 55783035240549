import styled from 'styled-components';

const drawerWidth = 200;
export const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    color: 'inherit',
    justifyContent: 'center',
    '@media only screen and (max-width: 600px)': {
      marginLeft: 0,
      left: 0,
      width: '100vw',
    },
  },
  toolBar: {
    paddingRight: 16,
    '@media only screen and (max-width: 767px)': {
      padding: 5,
    },
  },
  menuButton: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    padding: theme.spacing.unit * 3,
    backgroundColor: 'whitesmoke',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    height: 90,
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: 30,
  },
  collapsedMenuIcons: {
    margin: '0 auto',
  },
  collapseIcon: {
    fontSize: 24,
    color: '#696969',
    cursor: 'pointer',
  },
});

export const notificationBox = {
  position: 'fixed',
  maxHeight: '75vh',
  overflowY: 'auto',
  boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
};
export const LayoutWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: 600px) {
    max-width: calc(100% - ${props => (props.isCollapsedMenuBar ? '70' : '200')}px);
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: space-between;

  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
`;

export const RightSection = styled.div`
  padding-right: ${props => (props.noPadding ? 0 : '40px')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 770px) {
    padding-right: 0;
  }
`;

export const ActionItem = styled.div`
  padding-left: 10px;
`;

export const Section = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Name = styled.div`
  font-size: ${props => props.inputFontSize || '21px'};
  text-align: center;
  color: #1a173b;
  max-width: 100%;
  overflow-wrap: break-word;
`;

export const CompanyName = styled.div`
  font-size: ${props => props.inputFontSize || '15px'};
  margin-top: 7px;
  color: ${({ color }) => color || '#1a173b'};
  overflow-wrap: break-word;
  max-width: 100%;
  padding: 0 5px;
`;

export const ButtonPostJob = styled.button`
  color: #fff;
  background-color: #0077ff;
  border-radius: 22px;
  min-width: 100px;
  padding: 3px 15px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
    filter: brightness(120%);
  }
`;

export const IncompleteCompanyInfo = styled.div`
  text-align: center;
  color: #ff0000;
  white-space: pre-line;

  #link_onboarding {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 770px) {
    display: none;
  }
`;

export const WrapperExtendPlan = styled.div`
  display: flex;
  height: 79vh;

  .wrapper_left {
    width: 50%;
    height: 100%;
    background-color: #f8f7ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7% 6% 10% 6%;
    overflow: auto;

    .avatar {
      background-color: lightgray;
      height: 190px;
      width: 190px;
      background-image: url(${({ src }) => src});
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
    }

    .author_name {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.46;
      letter-spacing: normal;
      color: #eb5b49;
      width: 100%;
      text-align: left;
    }

    .quote {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: #818182;
    }
  }

  .wrapper_right {
    width: 50%;
    height: 100%;
    padding-top: 2%;
    text-align: center;
    overflow: auto;

    .title {
      color: #283583;
      font-size: 2rem;
      text-align: center;
    }

    button {
      background-color: #fff;
      border 1px solid #0800b4;
      color: #0800b4;
      padding: 8px 22px;
      cursor: pointer;
    }
  }

  .profit {
    overflow: auto;
    margin: 22px 0;
    padding: 0 22px;
    text-align: left;
  }

  @media only screen and (max-width: 767px) {
    .wrapper_right {
      width: 100%;

      .title {
        margin-top: 22px;
      }
    }

    .wrapper_left {
      display: none;
    }
  }
`;

export const TextPermisstion = styled.div`
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  font-size: 14px;
`;

export const HiddenBreakpoint = styled.div`
  @media (max-width: ${({ breakPoint }) => breakPoint || '600'}px) {
    display: none;
  }
`;
