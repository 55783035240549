import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 600px;
  min-height: 250px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const ModalTitle = styled.h1`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px
  margin-bottom: -10px
`;
export const ContainerModalLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  h6 {
    color: #fff;
    margin-left: 10px;
  }
`;
