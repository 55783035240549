import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Create } from '@material-ui/icons';
import { Typography, IconButton } from '@material-ui/core';

import { compose } from 'redux';
import { connect } from 'react-redux';

import ModalAddShortDescription from './ModalAddShortDescription';

const CompanyInfo = ({ profile, t }) => {
  const description = profile.description || t('profile.header.no_info');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenEditDescription = () => {
    setIsOpenModal(true);
  };

  const handleCloseEditDescription = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="pl-10 c:black">
        <Typography className="pb-16 font-bold fs-16 xl:fs-20" variant="h5" component="h3">
          {t('profile.about')}
        </Typography>
      </div>
      <div className="pl-40">
        <div className="flex">
          <div className="fs-16 c:#1a173b">{t('profile.short_description')}</div>
          <IconButton
            className="lg:-mt-5 lg:p-12 -mt-16 p-8 outline-none w-30 h-30"
            onClick={handleOpenEditDescription}
          >
            <Create className="w-13 h-13" />
          </IconButton>
        </div>
        <div className="mt-20">
          <div className="fs-14 c:#1a173b" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
      {isOpenModal && (
        <ModalAddShortDescription
          isOpenModal={isOpenModal}
          onCloseModal={handleCloseEditDescription}
          description={profile.description}
        />
      )}
    </>
  );
};

const mapStatetoProps = state => ({
  // isLoading: getLoadingProfile(state),
});

export default compose(withTranslation(), connect(mapStatetoProps))(CompanyInfo);
