import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 650px;
  min-height: 300px;
  text-align: center;
  max-width: 80vw;
`;

export const ModalTitle = styled.h1`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px
  margin-bottom: -10px
`;
export const ContainerModalLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;

  h6 {
    color: #fff;
    margin-left: 10px;
  }
`;

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    outline: 'none',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  modalHeader: {
    position: 'relative',
    paddingBottom: 10,
  },
  btnClose: {
    position: 'absolute',
    top: -15,
    right: 0,
  },
  menu: {
    width: 200,
  },
  modalFooter: {
    textAlign: 'center',
    paddingBottom: 20,
  },
  button: {
    borderRadius: '20px',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
});

export default styles;
