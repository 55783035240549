import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotSuccess, forgotWatcher } from 'slices/signup/signupSlice';
import ForgotConfirmModal from './ForgotConfirmModal';

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    forgotSuccess,
    forgotWatcher,
  },
  dispatch,
);

const mapStateToProps = state => ({
  email: state.signup.email,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotConfirmModal);
