import styled from 'styled-components';

export const CloseIconWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
`;

export const ModalSection = styled.div`
  max-height: 100%;
  max-width: 800px;
  @media only screen and (max-width: 768px) {
    max-width: 400px;
  }
`;
