import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Modal, withStyles, IconButton, Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import {
  ModalSection,
  LoginModalContainer,
  FormContainer,
  FormSection,
  FieldAlignCenter,
  LeftForgotSection,
  RowContainer,
  RegisterNow,
  ForgotPasswordLink,
  TextEmail,
  RightForgotSection,
  CloseIconWrap,
} from '../styled';
import { validateEmailCommon } from '../../../utils/help';

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 300,
    width: 300,
  },
});

class ForgotModal extends Component {
  // static propTypes = {
  //   setEmailForgot: PropTypes.func.isRequired,
  //   resetFormForgot: PropTypes.func.isRequired,
  //   isCandidate: PropTypes.bool.isRequired,
  //   signupFacebookWatcher: PropTypes.func.isRequired,
  //   forgotWatcher: PropTypes.func.isRequired,
  //   open: PropTypes.bool.isRequired,
  //   handleClose: PropTypes.func.isRequired,
  //   classes: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired,
  //   handleOpenLoginModal: PropTypes.func.isRequired,
  //   handleOpenRegisterModal: PropTypes.func.isRequired,
  //   signupLinkedinWatcher: PropTypes.func.isRequired,
  //   t: PropTypes.func.isRequired,
  // };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleSubmit = () => {
    const { email } = this.state;
    const { forgotWatcher, handleOpenForgotConfirmModal } = this.props;
    if (this.validateEmail(email)) {
      forgotWatcher({ email });
      handleOpenForgotConfirmModal();
    }
  };

  validateEmail = (email) => {
    const { t } = this.props;
    if (!email) {
      this.setState({ error: t('invalid_email') });
      return false;
    }
    if (email && validateEmailCommon(email)) {
      this.setState({ error: '' });
      return true;
    }
    this.setState({ error: t('login_modal.type_your_email') });
    return false;
  }

  handleFailure = () => { };

  render() {
    const { email, error } = this.state;
    const {
      open,
      handleClose,
      classes,
      handleOpenLoginModal,
      handleOpenRegisterModal,
      isCandidate,
      t,
    } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalSection className={classes.paper}>
          <CloseIconWrap>
            <IconButton
              style={{ outline: 'none' }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </CloseIconWrap>
          <LoginModalContainer>
            <Grid container style={{ display: 'unset' }}>
              <Grid item style={{ display: 'flex' }}>
                <Grid item md={12} style={{ width: '100%' }}>
                  <LeftForgotSection>
                    <h1
                      className="c:black fs-20 font-bold text-center mb-15 mt-15"
                    >
                      {t('login_modal.forgot_password')}
                    </h1>
                    <FormSection>
                      <FormContainer>
                        <TextEmail>
                          {t('login_modal.type_your_email')}
                        </TextEmail>
                        <TextField
                          id="outlined-adornment-email"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          variant="outlined"
                          type="text"
                          label="Email"
                          value={email}
                          helperText={error}
                          error={!!error}
                          onChange={this.handleChange('email')}
                        />

                        <FieldAlignCenter style={{ paddingBottom: 40 }}>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.margin}
                            onClick={this.handleSubmit}
                          >
                            {t('login_modal.reset')}
                          </Button>
                        </FieldAlignCenter>

                        <RowContainer>
                          <RegisterNow onClick={handleOpenRegisterModal}>
                            {t('login_modal.register_now')}
                          </RegisterNow>
                          <ForgotPasswordLink onClick={handleOpenLoginModal}>
                            {t('login_modal.login_now')}
                          </ForgotPasswordLink>
                        </RowContainer>
                      </FormContainer>
                    </FormSection>
                  </LeftForgotSection>
                </Grid>
                <Grid item md={12}>
                  <RightForgotSection
                    isCandidate={isCandidate}
                    style={{ height: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </LoginModalContainer>
        </ModalSection>
      </Modal>
    );
  }
}

export default compose(withStyles(styles), withTranslation())(ForgotModal);
