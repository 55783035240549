import React from 'react';
import { Modal, DialogContent } from '@material-ui/core';
import FormUploadAvatar from './FormUploadAvatar';

const UploadAvatar = props => {
  const getAvatar = dataFromChild => {
    props.onAvatarChange(dataFromChild);
  };

  const { isOpenModal, onCloseModal } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpenModal}
      onClose={onCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
      }}
    >
      <DialogContent style={{ flex: 'none', outline: 'none' }}>
        {isOpenModal ? <FormUploadAvatar onCloseModal={onCloseModal} getCallback={getAvatar} /> : null}
      </DialogContent>
    </Modal>
  );
};

export default UploadAvatar;
