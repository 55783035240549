import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobs: [],
  isLoading: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    goJobsPage: () => {},
    getJobs: (state) => {
      state.isLoading = true;
    },
    getJobsSuccess: (state, action) => {
      state.isLoading = false;
      state.jobs = action.payload;
    },
    getJobsFailed: (state) => {
      state.isLoading = false;
      state.jobs = [];
    },
  },
});

export const { goJobsPage, getJobs, getJobsSuccess, getJobsFailed } = registrationSlice.actions;

export default registrationSlice.reducer;
