import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPasswordWatcher } from 'slices/signup/signupSlice';
import ResetPassword from './ResetPassword';

const mapStateToProps = state => ({
  loading: state.signup.isLoadingReset,
  errors: state.signup.errorsReset,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPasswordWatcher,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
