import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { parseQuery } from 'utils/help';
import { withStyles, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import { MainContent, FormContainer, Title, ButtonReset, ButtonContainer } from './styled';
import { Topbar, Footer } from '../../components/LandingPage';
import SnipperLoading from '../../components/Common/SnipperLoading';

const styles = theme => ({
  icon: {
    fontSize: '50px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    width: 300,
  },
});

class ResetPassword extends Component {
  state = {
    new_password1: '',
    new_password2: '',
    showNewPassword1: false,
    showNewPassword2: false,
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowNewPassword1 = () => {
    this.setState(state => ({ showNewPassword1: !state.showNewPassword1 }));
  };

  handleClickShowNewPassword2 = () => {
    this.setState(state => ({
      showNewPassword2: !state.showNewPassword2,
    }));
  };

  handleSubmit = () => {
    const { new_password1, new_password2 } = this.state;
    const { token, uid } = parseQuery();
    const { resetPasswordWatcher } = this.props;
    if (token && uid) {
      const params = {
        token,
        uid,
        new_password1,
        new_password2,
      };
      resetPasswordWatcher(params);
    }
  };

  render() {
    const { classes, errors = {}, t, loading } = this.props;
    console.log("ResetPassword -> render -> errors", errors)
    console.log("ResetPassword -> render -> loading", loading)
    const { showNewPassword1, new_password1, new_password2, showNewPassword2 } = this.state;
    return (
      <div>
        <SnipperLoading
          loading={loading}
          text={`${t('loading')}`}
          cover
        />
        <Topbar target="candidates" />
        <MainContent>
          <FormContainer>
            <Title>{t('reset.reset_pw')}</Title>
            <TextField
              id="outlined-adornment-new-password-1"
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              type={showNewPassword1 ? 'text' : 'password'}
              label={t('login_modal.password')}
              value={new_password1}
              helperText={errors.new_password1 && errors.new_password1.length > 0 && errors.new_password1[0]}
              error={errors.new_password1 && errors.new_password1.length > 0}
              onChange={this.handleChange('new_password1')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword1}>
                      {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="outlined-adornment-new-password-2"
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              type={showNewPassword2 ? 'text' : 'password'}
              label={t('reset.type_again_pw')}
              value={new_password2}
              helperText={errors.new_password2 && errors.new_password2.length > 0 && errors.new_password2[0]}
              error={errors.new_password2 && errors.new_password2.length > 0}
              onChange={this.handleChange('new_password2')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword2}>
                      {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ButtonContainer>
              <ButtonReset onClick={this.handleSubmit}>{t('reset.reset_pw').toUpperCase()}</ButtonReset>
            </ButtonContainer>
          </FormContainer>
        </MainContent>
        <Footer />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPasswordWatcher: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles)(ResetPassword));
