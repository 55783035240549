import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  margin-top: 18px;
  @media (min-width: 992px) {
    font-size: 37px;
    line-height: 43px;
    margin-top: 0px;
  }
`;

export const TitleSection = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
  font-size: 16px;
  line-height: 19px;
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 33px;
  }
`;

export const BodyText = styled.div`
  font-size: 10px;
  line-height: 18px;
  @media (min-width: 992px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const GrayBodyText = styled(BodyText)`
  color: #3D5154;
`;

export const StyledCol = styled(Col)`
  padding: 0 30px !important;
`;

export const CenterFlexCol = styled(StyledCol)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const RowRevert = styled(Row)`
  flex-direction: row-reverse;
`;

export const SectionPadding = styled.div`
  height: 36px;
  @media (min-width: 992px) {
    height: 80px;
  }
`;

export const Banner = styled.div`
  @media (min-width: 992px) {
    padding: 3vw 5vw 0 5vw;
  }
  @media (min-width: 1445px) {
    padding: 3vw 5vw;
  }
`;
