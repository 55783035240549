import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, AppBar, Tab, Tabs } from '@material-ui/core';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: 'white',
    color: 'black',
    zIndex: 2,
  },
});

/**
 * Tab Setting
 * @param {String} position position AppBar Material UI
 * @param {Number} value current position tab
 * @param {Function} onChange handle change tab
 * @param {Object} classes custom style material ui
 */
const TabSetting = ({ position, value, onChange, classes }) => {
  const { t } = useTranslation();

  return (
    <AppBar position={position} className={`box-shadow-2 ${classes.bar}`}>
      <Tabs
        value={value}
        onChange={onChange}
        indicatorColor="primary"
        id="scrollable-tabs"
        variant="scrollable"
        scrollButtons="auto"
        style={{ display: 'unset' }}
      >
        <Tab value={0} label={t('profile.about')} className="tab-default" />
        <Tab value={1} label={t('profile.payment')} className="tab-default" />
      </Tabs>
    </AppBar>
  );
};

TabSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  position: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TabSetting);
