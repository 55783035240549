import { push } from 'connected-react-router';
import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';
import { ROUTES } from 'configs/routes';

import { goJobsPage, getJobs, getJobsSuccess, getJobsFailed } from 'slices/jobsList/jobsListSlice';

function* goJobsSaga() {
  yield put(push(ROUTES.JOBS));
}

function* getJobsSaga(action) {
  const response = yield call(() => API.get(API_ROUTES.JOBS, action.payload));
  if (response.ok) {
    yield put(getJobsSuccess(response.data));
  } else {
    yield put(getJobsFailed(response.data));
  }
}

function* registrationSaga() {
  yield takeLatest(goJobsPage, goJobsSaga);
  yield takeLatest(getJobs, getJobsSaga);
}

export default registrationSaga;
