import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';

import {
  dashboardWatcher,
  updatedashboardSuccess,
  updatedashboardFailed,
  analyticWatcher,
  analyticSuccess,
  analyticFailed,
} from './dashboardSlice';

function* dashboardEffectSaga(action) {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.get(API_ROUTES.DASHBOARD, action.payload, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(updatedashboardSuccess(response.data));
  } else {
    yield put(updatedashboardFailed(response.data));
  }
}

function* analyticEffectSaga() {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.get(API_ROUTES.ANALYTIC, {}, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(analyticSuccess(response.data));
  } else {
    yield put(analyticFailed(response.data));
  }
}

function* registrationSaga() {
  yield takeLatest(dashboardWatcher, dashboardEffectSaga);
  yield takeLatest(analyticWatcher, analyticEffectSaga);
}

export default registrationSaga;
