import React from 'react';
import { Modal, DialogContent } from '@material-ui/core';
import FormAddSummary from './FormAddSummary';

const ModalAddEducation = props => {
  const { isOpenModal, onCloseModal, description } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpenModal}
      onClose={onCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
      }}
    >
      <DialogContent style={{ flex: 'none' }}>
        {isOpenModal ? <FormAddSummary onCloseModal={onCloseModal} description={description} /> : null}
      </DialogContent>
    </Modal>
  );
};

export default ModalAddEducation;
