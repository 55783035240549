import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import _isEmpty from 'lodash/isEmpty';

const styles = themes => ({
  container: {
    padding: '0px 14px 0px 0',
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    // boxShadow: ' 0px -8px 30px -12px rgba(0,0,0,0.26)',

    '@media (max-width: 768px)': {
      padding: '8px 0',
    },
  },
  landingContainer: {
    marginTop: -40,
  },
  rowPerPage: {
    height: '100%',
    textAlign: 'right',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  optionRowPerPage: {
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '100%',
    marginLeft: 16,
  },
  offset: {
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '100%',
    marginLeft: 12,
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

class PaginationBar extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    dataSum: PropTypes.number,
    requestData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isFixed: PropTypes.bool.isRequired,
    offset: PropTypes.number.isRequired,
    style: PropTypes.any,
    MenuProps: PropTypes.any,
    t: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);
    const { dataSum } = props;
    const maxPage = dataSum / 5;
    this.state = {
      currentPage: 1,
      maxPage: Math.ceil(maxPage),
      rowPerPage: this.props.limit ? this.props.limit : 5,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRowPerPageChange = this.handleRowPerPageChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.offset === 0) {
      this.setState({ currentPage: 1 });
    }
  }

  handleChangePage = type => {
    const { dataSum, requestData, offset } = this.props;
    const { rowPerPage } = this.state;
    let newOffset;
    let { currentPage } = this.state;

    if (type === 'next' && currentPage < dataSum / rowPerPage) {
      newOffset = offset + parseInt(rowPerPage, 10);
      currentPage += 1;
    } else if (type === 'previous' && currentPage > 1) {
      newOffset = offset - parseInt(rowPerPage, 10);
      currentPage -= 1;
    } else return;
    this.setState({ offset: newOffset, currentPage }, () => {
      requestData(newOffset, parseInt(this.state.rowPerPage, 10));
    });
  };

  handleRowPerPageChange(event) {
    const { requestData, dataSum } = this.props;
    const { currentPage } = this.state;
    const maxPage = Math.ceil(dataSum / event.target.value);
    const offset =
      (currentPage <= maxPage ? currentPage - 1 : maxPage - 1) *
      event.target.value;
    this.setState(
      {
        rowPerPage: event.target.value,
        maxPage,
      },
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        requestData(parseInt(offset, 10), parseInt(this.state.rowPerPage, 10));
      },
    );
  }

  render() {
    const {
      data,
      dataSum,
      isLoading,
      isFixed,
      offset,
      style,
      MenuProps,
      t,
      classes,
      isLanding,
    } = this.props;
    const { currentPage, rowPerPage } = this.state;
    const positionStyle = !isFixed ? 'relative' : 'fixed';

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const DefaultMenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          maxWidth: 55,
        },
      },
      MenuListProps: {
        style: {
          minWidth: 50,
        },
      },
    };

    const setMenuProps = MenuProps
      ? { ...DefaultMenuProps, MenuProps }
      : DefaultMenuProps;

    return (
      <Grid
        item
        xs={12}
        container
        hidden={isLoading}
        justify="flex-end"
        style={{
          ...style || { position: `${positionStyle}` },
          ...(!dataSum || dataSum < 1) && { marginTop: 0 },
        }}
        className={isLanding ? classes.landingContainer : classes.container}
      >
        <Typography className={classes.rowPerPage}>
          {t('row_per_page')}
        </Typography>
        <FormControl
          className={classes.optionRowPerPage}
          disabled={dataSum === 0}
        >
          <Select
            value={rowPerPage}
            onChange={this.handleRowPerPageChange}
            disableUnderline
            style={{
              fontSize: 14,
            }}
            MenuProps={setMenuProps}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.offset}>
          {!_isEmpty(data) ? offset + 1 : offset}-
          {offset + (!_isEmpty(data) ? data.length : 0)} {t('of')} {dataSum}
        </div>
        <div>
          <IconButton
            onClick={() => this.handleChangePage('previous')}
            disabled={currentPage === 1}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => this.handleChangePage('next')}
            // style={{ color: "rgba(0, 0, 0, 0.87)" }}
            disabled={dataSum / rowPerPage - currentPage <= 0}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(PaginationBar));
