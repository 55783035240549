import React, { useState } from 'react';
import Button  from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
// components
import { Footer, Topbar } from 'components/LandingPage';
import InViewMonitor from 'react-inview-monitor';
import { withTranslation } from 'react-i18next';
import { LoginModal, RegisterModal, ForgotModal, ForgotConfirm } from 'components/Modal';
// styles
import banner from 'assets/images/landingBanner.svg';

const LandingPage = (props) => {
  const { t } = props;

  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
  const [isOpenForgotModal, setIsOpenForgotModal] = useState(false);
  const [isOpenForgotConfirm, setIsOpenForgotConfirm] = useState(false);

  const handleOpenLogin = () => {
    setIsOpenLoginModal(true);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenRegister = () => {
    setIsOpenRegisterModal(true);
    setIsOpenLoginModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgot = () => {
    setIsOpenForgotModal(true);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgotConfirm = () => {
    setIsOpenForgotConfirm(true);
    setIsOpenForgotModal(false);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
  };

  const closeAllModal = () => {
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  return (
    <div className="fixed inset-x-0 inset-y-0 overflow-x-hidden">
      <Topbar />
      <Grid fluid>
        <div className="lg:pb-0 lg:px-70 lg:pt-42 xl:py-42">
          <Grid container className="flex-row-reverse">
            <Grid xs={12} md={6} className="text-center flex justify-center flex-col text-center md:text-left py-0 px-30">
              <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated zoomIn">
                <img src={banner} className="img-fluid" alt="banner" />
              </InViewMonitor>
            </Grid>
            <Grid xs={12} md={6} className="text-center flex justify-center flex-col text-center md:text-left py-0 px-30">
              <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated zoomIn">
                <div className="text-center">
                  <div
                    className="c:#283583 xl:fs-50 fs-30 font-bold"
                  >
                    {t('landing.reseller')}
                  </div>
                  <div
                    className="c:#283583 xl:fs-50 fs-20 font-bold"
                  >
                    {t('landing.partners_program')}
                  </div>
                  <div className="fs-18 mb-5">{t('landing.text_1')}</div>
                  <div className="fs-18 mb-5">{t('landing.text_2')}</div>
                  <div className="fs-18 mb-5">{t('landing.text_3')}</div>
                  <div className="fs-18 mb-5">{t('landing.text_4')}</div>
                  <div className="fs-18 mb-5">{t('landing.text_5')}</div>
                  <div className="fs-18 mb-5">{t('landing.text_6')}</div>
                  <div className="fs-18 mb-15">{t('landing.text_7')}</div>
                  <Button
                    className="rounded-full h-35 bg-c:#283583 c:white mt-10 outline-none"
                    variant="contained"
                    onClick={handleOpenRegister}
                  >
                    {t('landing.button')}
                  </Button>
                </div>
              </InViewMonitor>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <div className="h-36 lg:h-80" />
      <Footer />
      <RegisterModal
        open={isOpenRegisterModal}
        handleOpenLoginModal={handleOpenLogin}
        handleClose={closeAllModal}
      />
      <LoginModal
        open={isOpenLoginModal}
        handleOpenRegisterModal={handleOpenRegister}
        handleOpenForgotModal={handleOpenForgot}
        handleClose={closeAllModal}
      />
      <ForgotModal
        open={isOpenForgotModal}
        handleOpenLoginModal={handleOpenLogin}
        handleOpenRegisterModal={handleOpenRegister}
        handleOpenForgotConfirmModal={handleOpenForgotConfirm}
        handleClose={closeAllModal}
      />
      <ForgotConfirm
        open={isOpenForgotConfirm}
        handleClose={closeAllModal}
      />
    </div>
  );
};

export default withTranslation()(LandingPage);
