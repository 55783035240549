import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export default ({ children }) => (
  <Grid container direction="row" justify="flex-start" alignItems="baseline">
    <Typography
      component="div"
      style={{
        paddingTop: 8,
        // paddingBottom: 8 * 3,
        width: '100%',
      }}
    >
      {children}
    </Typography>
  </Grid>
);
