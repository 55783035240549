import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import ModalEditProfile from './Modal/EditProfile';

class EditProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      isOpenModalConfrim: false,
      isRequesting: false,
    };
  }

  handleOpenModalEditProfile = () => {
    this.setState({ isOpenModal: true });
  };

  handleCloseModalEditProfile = () => {
    this.setState({ isOpenModal: false, isOpenModalConfrim: false });
  };

  handleOpenModalConfrim = () => {
    this.setState({ isOpenModalConfrim: true });
  };

  handleCloseModalConfrim = () => {
    this.setState({ isOpenModalConfrim: false });
  };

  render() {
    const { profile } = this.props;
    const { isOpenModal, isOpenModalConfrim, isRequesting } = this.state;

    return (
      <>
        <IconButton
          className="lg:-mt-5 lg:p-12 -mt-16 p-8 outline-none"
          isRequesting={isRequesting}
          onClick={this.handleOpenModalEditProfile}
        >
          <Create />
        </IconButton>
        {isOpenModal && (
          <ModalEditProfile
            isOpenModal={isOpenModal}
            onCloseModal={this.handleCloseModalEditProfile}
            handleOpenModalConfrim={this.handleOpenModalConfrim}
            isOpenModalConfrim={isOpenModalConfrim}
            onCloseModalConfrim={this.handleCloseModalConfrim}
            profile={profile}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(EditProfile);
