const specialScreen = [
  'Jobs',
  'Công Việc',
  'Pekerjaan',
  'Applications',
  'Ứng tuyển',
  'Aplikasi',
  'Job Detail Page',
  'Profile',
  'Candidates',
  'Kandidat',
  'Ứng viên',
  'Thí sinh',
  'Calon',
];

export { specialScreen };
