import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import _get from 'lodash/get';

const RenderAvatar = ({ width, height, profile, classes, cursor = false, handleClick }) => {
  const avatar = _get(profile, 'avatar', '');
  if (avatar) {
    return (
      <Avatar
        style={{ width, height, cursor: cursor ? 'pointer' : 'unset' }}
        className={classes.mainAvatar}
        src={avatar}
        onClick={handleClick}
      />

    );
  }
  return <AccountCircle style={{ width, height }} />;
};

RenderAvatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  classes: PropTypes.any,
  profile: PropTypes.object,
  detail: PropTypes.object,
  cursor: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
};

export default RenderAvatar;
