import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled.button`
  display: block;
  width: 152px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  appearance: none;
  outline: none;
  background-color: ${props => (props.isGray ? '#eee7ff' : '#ffe7e7')};
`;

export const StyledImages = styled.img`
  text-align: left;
  display: block;
  padding-right: 10px;
  max-width: 35;
`;

export const StyledImagesLabel = styled.img`
  text-align: left;
  // padding-left: 15px;
`;

export const StyledImagesLabelMini = styled.img`
  text-align: left;
  // padding-left: 6px;
`;

export const LastChangePassword = styled.p`
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

export const FormContainer = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
    padding: 0 8px;
  }
`;

export const Label = styled.p`
  font-size: 14px;
  color: #1a173b;
  letter-spacing: 0.2px;
  margin-top: 0.6rem;
  word-wrap: break-word;
`;

export const LabelContainer = styled.div`
  font-size: 14px;
  color: #1a173b;
  letter-spacing: 0.2px;
  margin-top: 0.6rem;
  word-wrap: break-word;
`;

export const WrapperIntegration = styled.div`
  background-color: white;
  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  color: black;
  margin: 0 16px;
  border-radius: 5px;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const WrapperSocialNetwork = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 66px;
  padding: 52px;
  @media only screen and (max-width: 768px) {
    padding: 52px 0;
    align-items: flex-start;
  }
`;

export const WrapperConnected = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ isFBPage }) => (isFBPage ? 10 : 23)}px;
  @media only screen and (max-width: 768px) {
    padding-top: ${({ isFBPage }) => (isFBPage ? 0 : 10)}px;
  }
`;

export const SocialNetwork = styled.div`
  width: 38%;
  color: black;
  text-align: left;
`;
export const ImageSN = styled.img`
  height: 100%;
  margin-right: 22px;
`;

export const ConnectButton = styled.div`
  width: 184px;
  height: 36px;
  line-height: 36px;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #dddd;
  }

  &:focus {
    outline: #0077ff solid 1px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const ConnectedButton = styled.div`
  width: 184px;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  color: white;
  background-color: #0077ff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;

export const Disconnect = styled.div`
  text-decoration: underline;
  margin-top: 8px;
  cursor: pointer;
  text-align: left;
  color: #4682b4;
  font-weight: bold;

  &:after {
    content: 'Disconnect';
  }
`;

export const ButtonDisconnect = styled.button`
  padding: 5px 22px;
  color: white;
  background-color: ${props => (props.disabled ? '#ddd' : '#0077ff')};
  border-radius: 18px;
  cursor: pointer;
  border: none;
  font-size: 14px;

  &:hover {
    filter: ${props => (props.disabled ? 'none' : 'brightness(122%)')};
  }

  &:focus {
    outline: none;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  padding-left: 22px;
  align-items: flex-end;
`;

export const BoxListCreditCard = styled.div`
  display: flex;
  padding-left: 22px;
  margin-top: 20px;
`;

export const CreditCardItem = styled.div`
  display: flex;
  border: 1px solid #979797;
  padding: 10px;
  width: 100%;
  flex-direction: column;
`;

export const CreditName = styled.div`
  display: flex;
  font-size: 14px;
`;

export const CreditCardContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CreditCardLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CreditNumber = styled.div`
  display: flex;
  color: #bbbbbb;
  font-size: 12px;
`;

export const BottomCreditCard = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const CreditExpires = styled.div`
  display: flex;
  color: #bbbbbb;
  margin-left: 20px;
  font-size: 12px;
`;

export const CreditCardRight = styled.div`
  display: flex;
  position: relative;
`;

export const MenuList = styled.div`
  position: absolute;
  display: none;
  top: 100%;
  z-index: 10;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
`;

export const MenuItem = styled.div``;

const styles = () => ({
  buttonAdd: {
    width: 100,
    fontSize: 14,
    border: 'solid 1px #979797',
    borderRadius: 0,
    textTransform: 'capitalize',
    marginTop: 10,
  },
  textNoti: {
    marginLeft: 30,
    fontSize: 14,
  },
  delete: {
    color: '#ff2626',
  },
  buttonMenu: {
    width: '100%',
    padding: '10px 14px',
  },
});

export default styles;
