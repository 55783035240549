import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: rgba(255, 255, 255, 1);
  width: 90%;
`;

export const LogoSection = styled.div`
  margin-top: 60px;
`;

export const ImageSection = styled.div`
  margin-top: 32px;
`;

export const DropDownLanguageContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 16px;
`;

export const TitleSection = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: rgba(145, 150, 152, 1);
  text-align: center;
  margin: 20px 0;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
`;
