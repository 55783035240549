import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  errors: {},
  isLogin: false,
  isShowReverified: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginWatcher: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isLogin = true;
    },
    loginFailed: (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.errors = action.payload;
      state.isShowReverified = !!((action.payload.non_field_errors && action.payload.non_field_errors[0] === 'E-mail is not verified.'));
    },
    logout: (state) => {
      state.data = [];
      state.isLoading = false;
      state.errors = {};
      state.isLogin = false;
      state.isShowReverified = false;
    },
    resendConfirmation: (state) => {
      state.isLoadingResend = true;
    },
    resendConfirmationSuccess: (state) => {
      state.isLoadingResend = false;
    },
  },
});

export const {
  loginWatcher,
  loginSuccess,
  loginFailed,
  logout,
  resendConfirmation,
  resendConfirmationSuccess,
} = loginSlice.actions;

export default loginSlice.reducer;
