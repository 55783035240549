import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { tncWatcher } from 'slices/tnc/tncSlice';
import TermsAndConditions from './TermsAndConditions';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      tncWatcher,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  tnc: state.tnc.tnc,
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
