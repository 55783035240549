/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
import moment from 'moment';
import _split from 'lodash/split';
import _join from 'lodash/join';
import _round from 'lodash/round';
import qs from 'querystring';

export const handleCopy = content => {
  const el = document.createElement('input');
  el.value = content;
  el.style.opacity = '0';
  document.body.appendChild(el);
  const editable = el.contentEditable;
  const { readOnly } = el;
  el.contentEditable = true;
  el.readOnly = false;
  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
  el.contentEditable = editable;
  el.readOnly = readOnly;
  el.setSelectionRange(0, 999999);
  el.select();
  document.execCommand('copy');
  el.remove();
};

export function isEmptyOrSpaces(str) {
  if (str) {
    return str.match(/^ *$/) !== null;
  }
  return true;
}

export function removeHtmlTags(html) {
  return (
    html &&
    html
      .replace(/(&nbsp;|<([^>]+)>)/gi, '')
      .replace(/&agrave;/gi, 'à')
      .replace(/&ocirc;/gi, 'ô')
      .replace(/&uacute;/gi, 'ú')
      .replace(/&ecirc;/gi, 'ê')
      .replace(/&acirc;/gi, 'â')
      .replace(/&aacute;/gi, 'á')
      .replace(/&iacute;/gi, 'í')
      .replace(/&oacute;/gi, 'ó')
      .replace(/&igrave;/gi, 'ì')
      .replace(/&ograve;/gi, 'ò')
  );
}

export function diffDate(date) {
  const today = moment();
  let updated = '';

  if (today.diff(date, 'days') === 0) {
    if (today.diff(date, 'hours') === 0) {
      updated = today.diff(date, 'minutes');
      if (updated < 1) {
        updated = 'just now';
      } else if (updated === 1) {
        updated += ' minute ago';
      } else {
        updated += ' minutes ago';
      }
    } else {
      updated = today.diff(date, 'hours');
      if (updated === 1) {
        updated += ' hour ago';
      } else {
        updated += ' hours ago';
      }
    }
  } else if (today.diff(date, 'days') > 0) {
    updated = today.diff(date, 'days');

    if (updated === 1) {
      updated += ' day ago';
    } else {
      updated += ' days ago';
    }
  }

  return updated;
}

export const checkProperties = obj => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === '' || obj[key] === 'undefined') {
      return false;
    }
  }
  return true;
};

export function addSpacingToLongText(str) {
  if (str && str.length) {
    return _join(
      _split(str, ' ').map(element => element.replace(/(.{20})/g, '$1 ')),
      ' ',
    );
  }
}

export function formatSalary(salary) {
  const salaryNumber = _round(Number(salary));
  let formatedSalary = '';

  switch (true) {
    case salaryNumber > 999949:
      formatedSalary = `${_round(salaryNumber / 1000000, 1)}m`;
      break;
    case salaryNumber > 9999:
      formatedSalary = `${_round(salaryNumber / 1000, 1)}k`;
      break;
    default:
      if (salaryNumber >= 1000) {
        formatedSalary = salaryNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        formatedSalary = salaryNumber;
      }
      break;
  }

  return formatedSalary;
}

export function removeObjectProperties(obj, props) {
  for (let i = 0; i < props.length; i++) {
    if (obj.hasOwnProperty(props[i])) {
      delete obj[props[i]];
    }
  }
  return obj;
}

export const parseQuery = (query = window.location.search.substring(1)) => qs.parse(query);

export const isValidExistedValue = value => {
  if (!value && value !== 0) {
    return false;
  }
  return true;
};

export const goToDetailPageWithTitleParam = (url, title) => {
  let titleUrl = '';

  if (url.includes('/candidate/detail/')) {
    titleUrl = qs.stringify({
      candidate: title && title.trim().replace(/ /gi, '-'),
    });
  } else {
    titleUrl = qs.stringify({
      title: title && title.trim().replace(/ /gi, '-'),
    });
  }

  window.open(`${url}?${titleUrl}`);
};

export const validateRegisterEmployerEmail = email => {
  const listInvalidDomain = [
    'gmail.com',
    'yahoo.com',
    'aol.com',
    'outlook.com',
    'zoho.com',
    'mail.com',
    'yahoo.com',
    'protonmail.com',
    'yandex.com',
    'icloud.com',
    'gxm.com',
    'thunderbird.net',
  ];

  const regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regExEmail.test(String(email).toLowerCase())) {
    const emailArrSplit = email.split('@');
    if (emailArrSplit.length !== 2) {
      return false;
    }
    if (listInvalidDomain.includes(emailArrSplit[1])) {
      return false;
    }
    return true;
  }
  return false;
};

export const validateEmailCommon = email => {
  const regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regExEmail.test(String(email).toLowerCase())) return true;
  return false;
};

export const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const randomColor = () => {
  const colorList = ['#901914', '#CE3D18', '#F6A715', '#FFD81B', '#4FB9BF', '#349DAE', '#1C3F6E'];

  return colorList[Math.floor(Math.random() * colorList.length)];
};

export const randomLightColor = index => {
  const colorList = [
    '#87CEFA',
    '#00FF00',
    '#FF69B4',
    '#FFEFD5',
    '#00FA9A',
    '#B0E0E6',
    '#FFFFF0',
    '#F5DEB3',
    '#D3D3D3',
    '#DAA520',
    '#7CFC00',
    '#E9967A',
    '#FFD700',
    '#FF7F50',
  ];
  const color =
    index || index === 0 ? colorList[index % colorList.length] : Math.floor(Math.random() * colorList.length);

  return color;
};

export const getRedirectByDomain = () => {
  let redirectUrl = process.env.REACT_APP_REDIRECT_URI_LINKEDIN;
  const currentUrl = window.location.origin;
  if (currentUrl) {
    redirectUrl = `${currentUrl}/linkedin`;
  }
  return redirectUrl;
};

export const formatPhoneNumberCountryCode = phone_number => {
  try {
    // country code and number should add " " so it can readed in update profile
    if (phone_number[0] === '0') {
      // default country code  is 65
      return phone_number.replace('0', '+65 ');
    }
    if (phone_number[0] === '+' && (phone_number[3] !== ' ' || phone_number[4] !== ' ')) {
      return `${phone_number.substr(0, 3)} ${phone_number.substr(3)}`;
    }
    return phone_number;
  } catch {
    return phone_number;
  }
};

export const getAge = date_of_birth => {
  const toDay = moment();
  const birthDay = moment(date_of_birth);
  return toDay.diff(birthDay, 'years');
};

export const isCandidatePage = (path = window.location.pathname, target = 'jobDetail') => {
  const listPage = [
    '/',
    '/job-lists',
    '/job-lists/',
    '/features',
    '/features/',
    '/landing-companies',
    '/landing-companies/',
    '/privacy-policy',
    '/privacy-policy/',
    '/review-company',
    '/quick-review',
    '/landing-personality',
    '/landing-personality/',
    '/landing-personality/result',
    '/landing-personality/result/',
    '/terms&conditions/candidate',
    '/terms&conditions/candidate/',
  ];

  return listPage.includes(path) || target === 'jobDetail';
};
