import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { getAuthData } from 'utils/sessionHandler';
import { history } from './store';
import LandingPage from './views/landingPages/LandingPage';
import JobsPage from './views/landingPages/JobsPage';
import OnBoarding from './views/onboarding';
import Dashboard from './views/dashboard';
import VerifyPage from './views/VerifyPage';
import Setting from './views/Setting/Settings';
import SignupSuccess from './views/SignupSuccess';
import ResetPassword from './views/ResetPassword';
import TermsAndConditions from './views/TermsAndConditions';
import { ROUTES } from './configs/routes';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { token } = getAuthData();
      if (token) {
        return (
          <Component {...props} />
        );
      }

      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

function App() {
  return (
    <ConnectedRouter history={history}>
      <div className="App" date="8/11">
        <Switch>
          <Route exact path={ROUTES.HOME}>
            <LandingPage />
          </Route>
          <Route exact path={ROUTES.JOBS}>
            <JobsPage />
          </Route>
          <Route exact path={ROUTES.ONBOARDING}>
            <OnBoarding />
          </Route>
          <Route exact path={ROUTES.VERIFY}>
            <VerifyPage />
          </Route>
          <Route exact path={ROUTES.SIGNUPSUCCESS}>
            <SignupSuccess />
          </Route>
          <Route exact path={ROUTES.FORGET}>
            <ResetPassword />
          </Route>
          <Route exact path={ROUTES.TNC}>
            <TermsAndConditions />
          </Route>
          <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <PrivateRoute exact path={ROUTES.SETTING} component={Setting} />
        </Switch>
      </div>
    </ConnectedRouter>
  );
}

export default App;
