import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
  IconButton,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  Fab,
  Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CommonModal from 'views/Setting/CommonModal';
import { updateProfile } from 'slices/profile/profileSlice';
import { getCountryAPI } from 'slices/profile/profileSaga';
import { stylesFormEdit, ResultFindRowCompany } from './styled';

const initialState = {
  accountName: '',
  bankName: '',
  bankNumber: '',
  bankAddress: '',
  country: '',
  swiftCode: '',
  accountNameError: '',
  bankNameError: '',
  bankNumberError: '',
  bankAddressError: '',
  countryError: '',
  swiftCodeError: '',
  countryList: [],
  isShowCountry: false,
  resultSearchCountry: {},
  isSetCountry: false,
};

class FormEditProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const countryList = await getCountryAPI();
    this.setState({ countryList });
    const {
      bankdetail,
    } = this.props;

    if (bankdetail) {
      this.setState({
        accountName: bankdetail.account_name,
        bankName: bankdetail.bank_branch_name,
        bankNumber: bankdetail.bank_account_number,
        bankAddress: bankdetail.bank_branch_address,
        country: bankdetail.country,
        swiftCode: bankdetail.swift_code,
        isSetCountry: bankdetail.country ? true : false,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isRequesting, onCloseModal } = this.props;
    if (isRequesting && !nextProps.isRequesting) {
      onCloseModal();
    }
  }

  handleSelectCountry = item => {
    const { name } = item;

    this.setState({
      country: name,
      isShowCountry: false,
      isSetCountry: true,
    });
  };

  handleClickCreateNewCountry = item => {
    this.setState({ country: item, isShowCountry: false });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleChangeCountry = event => {
    const { value } = event.target;
    const { countryList } = this.state;
    const searchCountry = countryList.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
    const isShowCountry = value !== '';
    this.setState({
      resultSearchCountry: searchCountry,
      country: value,
      isShowCountry,
      isSetCountry: false,
    });
  };

  validate = () => {
    const { t } = this.props;
    const {
      accountName,
      bankAddress,
      bankName,
      bankNumber,
      country,
      swiftCode,
    } = this.state;
    let accountNameError = '';
    let bankNameError = '';
    let bankAddressError = '';
    let countryError = '';
    let swiftCodeError = '';
    let bankNumberError = '';

    let check = true;

    if (accountName.length > 100) {
      accountNameError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (bankAddress.length > 100) {
      bankAddressError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (bankName.length > 100) {
      bankNameError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (bankNumber.length > 100) {
      bankNumberError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (country.length > 100) {
      countryError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (swiftCode.length > 100) {
      swiftCodeError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    const updateState = {
      accountNameError,
      bankAddressError,
      bankNameError,
      bankNumberError,
      countryError,
      swiftCodeError,
    };
    this.setState(updateState);

    return check;
  };

  isDisableSubmit = () => {
    const {
      accountName,
      bankName,
      bankNumber,
      isSetCountry,
      swiftCode,
      bankAddress,
    } = this.state;
    if (!accountName || !bankName || !bankNumber || !isSetCountry || !swiftCode || !bankAddress) {
      return true;
    }
    return false;
  };

  handleSubmit = () => {
    const { updateProfile } = this.props;
    const {
      accountName,
      bankName,
      bankNumber,
      country,
      swiftCode,
      bankAddress,
    } = this.state;
    if (this.validate()) {
      const params = {
        bankdetail: {
          account_name: accountName,
          bank_branch_name: bankName,
          bank_account_number: bankNumber,
          country: country,
          swift_code: swiftCode,
          bank_branch_address: bankAddress,
        },
      };
      updateProfile(params);
    }
  };

  handleOpenModalDeleteAccount = () => {
    this.setState({ isOpenDeleteConfirmModal: true });
  };

  handleCloseModalDeleteConfirm = () => {
    this.setState({ isOpenDeleteConfirmModal: false });
  };

  handleChangeAddress = (address) => {
    this.setState({ address: address || '' });
  };

  handleSelect = address => {
    this.setState({ address });
  };

  render() {
    const isDisableSubmit = this.isDisableSubmit();
    const {
      onCloseModal,
      classes,
      isRequesting,
      isOpenModal,
      t,
    } = this.props;
    const {
      accountName,
      bankName,
      bankNumber,
      bankAddress,
      country,
      swiftCode,
      accountNameError,
      bankNameError,
      bankNumberError,
      bankAddressError,
      countryError,
      swiftCodeError,
      isShowCountry,
      resultSearchCountry,
    } = this.state;

    return (
      <CommonModal onCloseModal={onCloseModal} isOpenModal={isOpenModal}>
        <div className={classes.paper}>
          <Modal open={isRequesting} disableBackdropClick disableEscapeKeyDown>
            <div className="flex items-center justify-center h-full c:black">
              <CircularProgress color="inherit" />
              <Typography variant="h6" id="modal-title">
                {t('bank.edit_bank')}
              </Typography>
            </div>
          </Modal>
          <div className="lg:w-900 max-h-600 overflow-auto">
            <div className={classes.modalHeader}>
              <h1 className="c:black fs-20 font-bold text-center -mb-15 mt-15">{t('bank.bank_detail')}</h1>
              <IconButton style={{ outline: 'none' }} className={classes.btnClose} onClick={onCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modal-body">
              <div className="lg:flex lg:justify-between mb-15">
                <div className="lg:w-47-p">
                  <div className="font-bold">{t('bank.account_name')}</div>
                  <div className="fs-12">{t('bank.account_name_des')}</div>
                  <TextField
                    fullWidth
                    id="account_name"
                    className="lg:mb-3 mb-15 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={accountName}
                    onChange={this.handleChange('accountName')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={accountNameError}
                    helperText={accountNameError}
                  />
                </div>

                <div className="lg:w-47-p">
                  <div className="font-bold">{t('bank.bank_name')}</div>
                  <div className="fs-12">{t('bank.bank_name_des')}</div>
                  <TextField
                    fullWidth
                    id="bank_name"
                    className="mb-3 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={bankName}
                    onChange={this.handleChange('bankName')}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={bankNameError}
                    helperText={bankNameError}
                  />
                </div>
              </div>

              <div className="lg:flex lg:justify-between mb-15">
                <div className="lg:w-47-p">
                  <div className="font-bold">{t('bank.bank_number')}</div>
                  <div className="fs-12">{t('bank.bank_number_des')}</div>
                  <TextField
                    fullWidth
                    id="bank_number"
                    type="number"
                    className="lg:mb-3 mb-15 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={bankNumber}
                    onChange={this.handleChange('bankNumber')}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={bankNumberError}
                    helperText={bankNumberError}
                  />
                </div>

                <div className="lg:w-47-p">
                  <div className="font-bold lg:mb-17">{t('bank.bank_address')}</div>
                  <TextField
                    fullWidth
                    id="bank_address"
                    className="mb-3 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={bankAddress}
                    onChange={this.handleChange('bankAddress')}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={bankAddressError}
                    helperText={bankAddressError}
                  />
                </div>
              </div>

              <div className="lg:flex lg:justify-between">
                <div className="lg:w-47-p">
                  <div className="font-bold lg:mb-17">{t('bank.country')}</div>
                  <TextField
                    fullWidth
                    id="country"
                    className="lg:mb-3 mb-15 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={country}
                    onChange={this.handleChangeCountry}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!(countryError !== '')}
                    helperText={countryError}
                  />
                  {isShowCountry && (
                    <Paper
                      style={{
                        width: '100%',
                        maxHeight: 221,
                        overflow: 'auto',
                        overflowX: 'hidden',
                      }}
                    >
                      {resultSearchCountry.length > 0 && resultSearchCountry.map(item => (
                        <ResultFindRowCompany key={item.id} onClick={() => this.handleSelectCountry(item)}>
                          {item.name}
                        </ResultFindRowCompany>
                      ))}
                    </Paper>
                  )}
                </div>

                <div className="lg:w-47-p">
                  <div className="font-bold lg:mb-17">{t('bank.swift_code')}</div>
                  <TextField
                    fullWidth
                    id="swift_code"
                    className="mb-3 mt-5"
                    InputProps={{ classes: { input: classes.inputSize } }}
                    value={swiftCode}
                    onChange={this.handleChange('swiftCode')}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={swiftCodeError}
                    helperText={swiftCodeError}
                  />
                </div>
              </div>
            </div>
            <div className="text-center pb-20">
              <Fab
                variant="extended"
                size="small"
                aria-label="Save"
                style={{
                  width: 150,
                  height: 40,
                  borderRadius: 30,
                  margin: 10,
                  backgroundColor: '#bcd9f9',
                  outline: 'none',
                }}
                disabled={isDisableSubmit}
                onClick={this.handleSubmit}
              >
                {t('profile.header.save')}
              </Fab>
              <div className="fs-14">{t('bank.note')}</div>
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

FormEditProfile.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  updateProfile: bindActionCreators(updateProfile, dispatch),
});

const mapStateToProps = state => ({
  isRequesting: state.profile.isLoadingUpdate,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(stylesFormEdit),
)(FormEditProfile);
