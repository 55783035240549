import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
// components
import { Footer, Topbar } from 'components/LandingPage';
import { Container } from 'react-bootstrap';
import InViewMonitor from 'react-inview-monitor';
import { withTranslation } from 'react-i18next';
import { LoginModal, RegisterModal, ForgotModal, ForgotConfirm } from 'components/Modal';
// styles
import {
  CenterFlexCol,
  RowRevert,
  SectionPadding,
  Banner,
} from 'components/Common/LandingSections/styled';
import 'components/Common/LandingSections/styles.css';
import banner from 'assets/images/landingBanner.svg';

const SignupSuccess = (props) => {
  const { t } = props;

  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
  const [isOpenForgotModal, setIsOpenForgotModal] = useState(false);
  const [isOpenForgotConfirm, setIsOpenForgotConfirm] = useState(false);

  const handleOpenLogin = () => {
    setIsOpenLoginModal(true);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenRegister = () => {
    setIsOpenRegisterModal(true);
    setIsOpenLoginModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgot = () => {
    setIsOpenForgotModal(true);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotConfirm(false);
  };

  const handleOpenForgotConfirm = () => {
    setIsOpenForgotConfirm(true);
    setIsOpenForgotModal(false);
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
  };

  const closeAllModal = () => {
    setIsOpenLoginModal(false);
    setIsOpenRegisterModal(false);
    setIsOpenForgotModal(false);
    setIsOpenForgotConfirm(false);
  };

  return (
    <div className="ld-container">
      <Topbar />
      <Container fluid>
        <Banner>
          <RowRevert>
            <CenterFlexCol xs={12} md={6} className="text-center">
              <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated zoomIn">
                <img src={banner} className="img-fluid" alt="banner" />
              </InViewMonitor>
            </CenterFlexCol>
            <CenterFlexCol xs={12} md={6}>
              <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated zoomIn">
                <div className="text-center">
                  <div
                    className="c:#283583 fs-50 font-bold"
                  >
                    {t('signup_success.9cv9')}
                  </div>
                  <div
                    className="c:#283583 fs-50 font-bold"
                  >
                    {t('landing.partners_program')}
                  </div>
                  <div className="fs-18 mb-5">{t('signup_success.text_1')}</div>
                  <div className="fs-18 mb-5">{t('signup_success.text_2')}</div>
                  <div className="fs-18 mb-5">{t('signup_success.text_3')}</div>
                  <div className="fs-18 mb-15">{t('signup_success.text_4')}</div>
                  <Button
                    className="rounded-full h-35 bg-c:#283583 c:white mt-20 outline-none"
                    variant="contained"
                    onClick={handleOpenRegister}
                  >
                    {t('landing.button')}
                  </Button>
                </div>
              </InViewMonitor>
            </CenterFlexCol>
          </RowRevert>
        </Banner>
      </Container>
      <SectionPadding />
      <Footer />
      <RegisterModal
        open={isOpenRegisterModal}
        handleOpenLoginModal={handleOpenLogin}
        handleClose={closeAllModal}
      />
      <LoginModal
        open={isOpenLoginModal}
        handleOpenRegisterModal={handleOpenRegister}
        handleOpenForgotModal={handleOpenForgot}
        handleClose={closeAllModal}
      />
      <ForgotModal
        open={isOpenForgotModal}
        handleOpenLoginModal={handleOpenLogin}
        handleOpenRegisterModal={handleOpenRegister}
        handleOpenForgotConfirmModal={handleOpenForgotConfirm}
        handleClose={closeAllModal}
      />
      <ForgotConfirm
        open={isOpenForgotConfirm}
        handleClose={closeAllModal}
      />
    </div>
  );
};

export default withTranslation()(SignupSuccess);
