import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

export const ButtonMenu = styled.button`
  background-color: ${props => props.backgroundColor};
  border-radius: 17px;
  width: 113px;
  height: 34px;
  color: white;
  border-width: 0;
  cursor: pointer;
  margin: 8px 10px;
`;

export const ButtonMenuGroup = styled.div`
  padding-left: 20px;
`;

export const StyledNavbar = styled(Navbar)`
  ${({ bgcolor }) => bgcolor && `
    background-color: ${bgcolor};
  `}

  ${({ isfixed }) => isfixed && `
    position: fixed !important;
    width: 100%
    background-color: white;
    // box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
  `}

  @media only screen and (max-width: 770px) {
    padding: 0 15px;
    heigh: 65px;

    .country-logo {
      margin-left: 0;
    }
  }
`;

export const CenterHeader = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 770px) {
    top: 20px;
  }

  @media only screen and (max-width: 440px) {
    position: initial;
    width: 100%;
    justify-content: center;
    padding: 8px 0;
    font-size: 18px;
    transform: none;
  }
`;
