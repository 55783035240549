import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from 'slices/jobsList/jobsListSlice';

const JobsPage = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobsList.jobs);

  useEffect(() => {
    dispatch(getJobs({ limit: 10, offset: 1 }));
  }, [dispatch]);

  console.log('jobs: ', jobs);
  return (
    <div>
      <h1>Jobs Page</h1>
      <p>Welcome to the Jobs Page</p>
    </div>
  );
};

export default JobsPage;
