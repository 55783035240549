import React, { PureComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles, IconButton, Typography, TextField, Fab, Modal, CircularProgress } from '@material-ui/core';
import { updateProfile } from 'slices/profile/profileSlice';
import { ModalContainer, ModalTitle, ContainerModalLoading } from './styled';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    outline: 'none',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginBottom: 7,
    '& fieldset': {
      borderRadius: 0,
    },
    '& input + svg': {
      display: 'none',
    },
  },
  modalHeader: {
    position: 'relative',
    paddingBottom: 10,
  },
  btnClose: {
    position: 'absolute',
    top: -15,
    right: 0,
  },
  menu: {
    width: '100%',
  },
  modalFooter: {
    textAlign: 'center',
    paddingBottom: 20,
  },
});

class FormAddSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
    };
  }

  componentDidMount = () => {
    const { description } = this.props;
    this.setState({ description });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isRequesting, onCloseModal } = this.props;
    if (isRequesting && !nextProps.isRequesting) {
      onCloseModal();
    }
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  isDisableSubmit = () => {
    const { description } = this.state;
    if (!description) {
      return true;
    }
  };

  handleSubmit = () => {
    const { updateProfile } = this.props;
    const { description } = this.state;
    const params = {
      description,
    };
    updateProfile(params);
  };

  render() {
    const isDisableSubmit = this.isDisableSubmit();
    const { onCloseModal, classes, isRequesting, t } = this.props;
    const { description } = this.state;

    return (
      <div className={classes.paper}>
        <Modal open={isRequesting} disableBackdropClick disableEscapeKeyDown>
          <ContainerModalLoading>
            <CircularProgress color="inherit" />
            <Typography variant="h6" id="modal-title">
              {t('loading')}
            </Typography>
          </ContainerModalLoading>
        </Modal>

        <ModalContainer>
          <div className={classes.modalHeader}>
            <ModalTitle>{t('profile.short_description')}</ModalTitle>
            <IconButton style={{ outline: 'none' }} className={classes.btnClose} onClick={onCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            {/* Summary */}
            <TextField
              fullWidth
              id="Description"
              label={t('profile.short_description')}
              value={description}
              className={classes.textField}
              onChange={this.handleChange('description')}
              margin="normal"
              variant="outlined"
              multiline
              rows={5}
              rowsMax={6}
            />
          </div>
          <div className={classes.modalFooter}>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="Save"
              style={{
                width: 290,
                height: 45,
                borderRadius: 30,
                marginBottom: -20,
              }}
              disabled={isDisableSubmit}
              onClick={this.handleSubmit}
            >
              Save
            </Fab>
          </div>
        </ModalContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateProfile: bindActionCreators(updateProfile, dispatch),
});

const mapStateToProps = state => ({
  isRequesting: state.profile.isLoadingUpdate,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles),
)(FormAddSummary);
