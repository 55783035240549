import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import persistConfig from 'configs/ReduxPersist';
import jobsListReducer from 'slices/jobsList/jobsListSlice';
import jobsListSaga from 'slices/jobsList/jobsListSaga';
import loginReducer from 'slices/login/loginSlice';
import loginSaga from 'slices/login/loginSaga';
import signupReducer from 'slices/signup/signupSlice';
import signupSaga from 'slices/signup/signupSaga';
import onboardingReducer from 'slices/onboarding/onboardingSlice';
import onboardingSaga from 'slices/onboarding/onboardingSaga';
import profileReducer from 'slices/profile/profileSlice';
import profileSaga from 'slices/profile/profileSaga';
import dashboardReducer from 'slices/dashboard/dashboardSlice';
import dashboardSaga from 'slices/dashboard/dashboardSaga';
import tncReducer from 'slices/tnc/tncSlice';
import tncSaga from 'slices/tnc/tncSaga';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    jobsList: jobsListReducer,
    login: loginReducer,
    signup: signupReducer,
    onboarding: onboardingReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    tnc: tncReducer,
  });

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    jobsListSaga(),
    loginSaga(),
    signupSaga(),
    onboardingSaga(),
    profileSaga(),
    dashboardSaga(),
    tncSaga(),
  ]);
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  middleware: [routerMiddleware(history), sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
