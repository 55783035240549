import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { withStyles } from '@material-ui/core/styles';
import ErrorBoundary from 'components/ErrorBoundary';
import DashboardLayout from 'components/Layout';
import PartnersMain from 'components/Dashboard';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    justifyContent: 'flex-start',
    textAlign: 'left',
    padding: '8px !important',
  },
  mobile: {
    ...theme.mixins.gutters(),
    padding: '8px !important',
  },
  mt20: {
    marginTop: '20px',
  },
});

const AssessmentListPage = props => {
  const { classes } = props;
  return (
    <DashboardLayout title="Dashboard" active="Dashboard">
      <ErrorBoundary>
        <MediaQuery query="(min-width: 769px)">
          <div className={classes.root}>
            <PartnersMain />
          </div>
        </MediaQuery>
        <MediaQuery query="(max-width: 768px)">
          <div className={classes.mobile}>
            <PartnersMain />
          </div>
        </MediaQuery>
      </ErrorBoundary>
    </DashboardLayout>
  );
};
AssessmentListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssessmentListPage);
