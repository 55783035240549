/* eslint-disable import/no-unresolved */
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { logout } from 'slices/login/loginSlice';
import { profileWatcher, collapseMenuBar } from 'slices/profile/profileSlice';

import DashboardLayout from './Layout';

const mapStateToProps = state => ({
  profile: state.profile.profile,
  isCollapsedMenuBar: state.profile.isCollapsedMenuBar,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      profileWatcher,
      logout,
      collapseMenuBar,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DashboardLayout);
