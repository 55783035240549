import { push } from 'connected-react-router';
import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import { toast } from 'react-toastify';
import { toastConfig } from 'utils/config';
import { ROUTES } from 'configs/routes';

import {
  signupWatcher,
  signupSuccess,
  signupFailed,
  verifyWatcher,
  forgotWatcher,
  forgotSuccess,
  resetPasswordWatcher,
  resetPasswordSuccess,
  resetPasswordFailed,
} from './signupSlice';

function* signupEffectSaga(action) {
  const response = yield call(() => API.post(API_ROUTES.SIGNUP, action.payload));
  if (response.ok) {
    yield put(signupSuccess(response.data));
    yield put(push(ROUTES.SIGNUPSUCCESS));
    toast.success(
      'Thanks for registering. Please check your email and spam folder for our verification email to verify your account.',
      toastConfig,
    );
  } else {
    yield put(signupFailed(response.data));
    const errorMessage = _get(response.data, 'non_field_errors[0]', '');
    if (errorMessage) toast.error(errorMessage, toastConfig);
  }
}

function* verifyEffectSaga(action) {
  const response = yield call(() => API.post(API_ROUTES.VERIFY, action.payload));
  if (response.ok) {
  } else {
    toast.error('verify is not success', toastConfig);
  }
}

function* forgotEffectSaga(action) {
  const response = yield call(() => API.post(API_ROUTES.FORGOT, action.payload));
  if (response.ok) {
    yield put(forgotSuccess(action.payload));
    const stringSuccess = response.data.message;
    toast.success(stringSuccess, toastConfig);
  } else {
    toast.error('error', toastConfig);
  }
}

function* resetEffectSaga(action) {
  const response = yield call(() => API.post(API_ROUTES.RESETPASSWORD, action.payload));
  if (response.ok) {
    yield put(resetPasswordSuccess(response.data));
    const stringSuccess = response.data.detail;
    toast.success(stringSuccess, toastConfig);
  } else {
    yield put(resetPasswordFailed(response.data));
    if (response.data.token) {
      const stringError = response.data.token;
      toast.error(stringError[0], toastConfig);
    }

    if (response.data.uid) {
      const stringError = response.data.uid;
      toast.error(stringError[0], toastConfig);
    }
  }
}

function* registrationSaga() {
  yield takeLatest(signupWatcher, signupEffectSaga);
  yield takeLatest(verifyWatcher, verifyEffectSaga);
  yield takeLatest(forgotWatcher, forgotEffectSaga);
  yield takeLatest(resetPasswordWatcher, resetEffectSaga);
}

export default registrationSaga;
