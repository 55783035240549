// Libs
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PaymentDetail from './PaymentInfo';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    justifyContent: 'flex-start',
    textAlign: 'left',
    padding: '8px !important',
  },
  mobile: {
    ...theme.mixins.gutters(),
    padding: '8px !important',
  },
  mt20: {
    marginTop: '20px',
  },
  background: {
    backgroundColor: 'white !important',
  },
});

const Payment = (props) => {
  const { profile } = props;
  return (
    <PaymentDetail profile={profile} />
  );
};

export default withStyles(styles)(Payment);
