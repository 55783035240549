/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Button, TextField, InputAdornment, IconButton, Checkbox, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { validateEmailCommon, parseQuery } from 'utils/help';
import SnipperLoading from 'components/Common/SnipperLoading';
import { Footer, Topbar } from 'components/LandingPage';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 400,
    width: 300,
  },
  // link: {
  //   textDecoration: 'underline',
  //   cursor: 'pointer',
  // },
});

const VerifyPage = (props) => {
  const { t, loginWatcher, classes, loading, errors, verifyWatcher } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [, setIsShowRegister] = useState(false);
  const [, setIsShowForgot] = useState(false);
  const [isAllowShowErrValidation, setIsAllowShowErrValidation] = useState(false);
  const { key: verifyKey } = parseQuery();

  useEffect(() => {
    if (verifyKey) {
      const data = {
        key: verifyKey,
      };
      verifyWatcher(data);
    }
  }, [verifyWatcher, verifyKey]);

  const handleSubmit = () => {
    setIsAllowShowErrValidation(true);
    if (
      email &&
      email.length > 0 &&
      password &&
      password.length > 0 &&
      validateEmailCommon(email)
    ) {
      loginWatcher({ email, password });
    }
  };

  const handleChange = prop => event => {
    // const { resetFormLogin } = this.props;
    const { target } = event;
    // resetFormLogin();
    const eventValue = prop === 'remember' ? target.checked : target.value;
    if (prop === 'email') {
      setEmail(eventValue);
    }
    if (prop === 'password') {
      setPassword(eventValue);
    }
    if (prop === 'remember') {
      setRemember(eventValue);
    }
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleOpenRegisterModal = () => {
    setIsShowRegister(true);
  };

  const handleOpenForgotModal = () => {
    setIsShowForgot(true);
  };

  return (
    <div className="ld-container">
      <SnipperLoading
        loading={loading}
        text={`${t('login')}...`}
        cover
      />
      <Topbar />
      <div className="text-center mt-60 mb-60">
        <div className="m-15">Thanks for verifying your email.</div>
        <div className="m-15">Please login using your credentials below</div>
        <div className="flex-center">
          <div className="w-300">
            <TextField
              id="outlined-adornment-email"
              className={classNames(
                classes.margin,
                classes.textField,
              )}
              variant="outlined"
              type="text"
              label="Email"
              value={email}
              helperText={
                (isAllowShowErrValidation &&
                  !email &&
                  t('msg_empty_email')) ||
                (isAllowShowErrValidation &&
                  !validateEmailCommon(email) &&
                  t('invalid_email')) ||
                (errors.email &&
                  errors.email.length > 0 &&
                  errors.email[0])
              }
              error={
                (errors.email && errors.email.length > 0) ||
                (isAllowShowErrValidation &&
                  (!email || !validateEmailCommon(email)))
              }
              onChange={handleChange('email')}
            />
            <TextField
              id="outlined-adornment-password1"
              className={classNames(
                classes.margin,
                classes.textField,
              )}
              variant="outlined"
              type={isShowPassword ? 'text' : 'password'}
              label={t('login_modal.password')}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  this.handleSubmit();
                }
              }}
              value={password}
              helperText={
                (isAllowShowErrValidation &&
                  !password &&
                  t('msg_empty_password')) ||
                (errors.password &&
                  errors.password.length > 0 &&
                  errors.password[0])
              }
              error={
                (errors.password && errors.password.length > 0) ||
                (isAllowShowErrValidation && !password)
              }
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {isShowPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* {showReverified && (
              <div
                className={classes.margin}
                style={{ color: 'red' }}
              >
                <div>{t('login_modal.appears_not_verified')}</div>
                <div>
                  {t('login_modal.please_click')}{' '}
                  <strong
                    className={classes.link}
                    onClick={this.handleReverified}
                  >
                    {t('login_modal.here')}
                  </strong>{' '}
                  {t('login_modal.to_resend')}
                </div>
              </div>
            )} */}
            <div className="flex justify-between">
              <div className="flex items-center">
                <Checkbox
                  checked={remember}
                  onChange={handleChange('remember')}
                  value="remember"
                />
                <div>{t('login_modal.remember_me')}</div>
              </div>

              <div className="flex items-center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={loading}
                  // className={classes.margin}
                  onClick={handleSubmit}
                >
                  {t('login')}
                </Button>
              </div>
            </div>

            <div className="flex justify-between mt-5">
              <div
                className="c:#5d8ffc fs-14 font-normal text-left pl-8 cursor-pointer"
                onClick={handleOpenRegisterModal}
              >
                {t('login_modal.register_now')}
              </div>
              <div
                className="c:#929699 fs-14 font-normal text-right cursor-pointer"
                onClick={handleOpenForgotModal}
              >
                {t('login_modal.forgot_password')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default compose(withStyles(styles), withTranslation())(VerifyPage);
