import React, { Component } from 'react';
import DropDownLanguage from 'components/LandingPage/DropDown';
import { Container, LogoSection, ImageSection, TitleSection, DropDownLanguageContainer } from './styled';
import logo from './onboarding-employer-9cv9-logo-01@2x.png';

class OnboardingLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Container>
        {!window.location.href.includes('9cv9.vn')
          && !window.location.href.includes('9cv9jobs.id')
          &&
          <DropDownLanguageContainer>
            <DropDownLanguage />
          </DropDownLanguageContainer>}
        <LogoSection>
          <img src={logo} alt="9cv9" width="186" height="69" />
        </LogoSection>
        {this.props.img && (
          <ImageSection>
            <img src={this.props.img} alt="basic-info" width="69" height="69" />
          </ImageSection>
        )}
        <TitleSection>{this.props.title}</TitleSection>
        {children}
      </Container>
    );
  }
}

export default OnboardingLayout;
