import styled from 'styled-components';

export const Label = styled.div`
  text-align: right;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  padding-top: 5px;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const ErrorMessage = styled.p`
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
`;

export const ActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;

  @media only screen and (max-width: 770px) {
    flex-direction: column-reverse;
  }
`;

export const RightActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 770px) {
    flex-direction: column-reverse;
  }
`;

export const PreviousButton = styled.div`
  height: 27px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #929699;
  cursor: pointer;
  padding-right: 50px;

  @media only screen and (max-width: 770px) {
    padding-right: 0;
  }
`;

export const AddSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 600px;
  padding-bottom: 50px;

  @media only screen and (max-width: 770px) {
    justify-content: center;
    width: 100%;
  }
`;

export const PrevSection = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 770px) {
    margin: 30px 0;
  }
`;

export const MainLabel = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

export const UploadImage = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  button {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 22px;
    margin-top: 10px;
    font-size: 1rem;

    &:hover {
      background-color: #dddd;
    }
  }

  @media only screen and (max-width: 770px) {
    margin: 0 auto;
  }
`;

export const CompanyImage = styled.div`
  width: 184px;
  height: 98px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: gray;

  .btn_remove {
    padding: 2px;
    cursor: pointer;
    &:hover {
      color: #ddd;
    }
  }
`;

export const CancelButton = styled.div`
  height: 27px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #929699;
  cursor: pointer;
  margin-top: 16px;
`;

export const LogoContainer = styled.div`
  width: 170px;
  position: relative;
  margin-left: 20px;
  display: flex;
  @media only screen and (max-width: 770px) {
    margin: 0 auto;
  }
`;

export const LogoImage = styled.img`
  width: auto;
  height: auto;
  max-width: 150px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 250px;
  text-align: left;
  padding-left: 10px;

  .react-tel-input {
    width: 100%;
  }
`;

export const OnboardingDescription = styled.div`
  display: flex;
  width: max-content;
  max-width: 100%;
  margin: auto;
`;

export const ResultFindRow = styled.div`
  line-height: 38px;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: #dddd;
  }
`;

export const AvatarImage = styled.img`
  width: auto;
  height: auto;
  max-width: 150px;
`;
