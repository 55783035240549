export const ROUTES = {
  HOME: '/',
  JOBS: '/jobs',
  ONBOARDING: '/onboarding',
  DASHBOARD: '/dashboard',
  VERIFY: '/verify',
  SETTING: '/settings',
  SIGNUPSUCCESS: '/signupsuccess',
  FORGET: '/forget',
  TNC: '/terms&condition',
};
