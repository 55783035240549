import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotWatcher } from 'slices/signup/signupSlice';
import ForgotModal from './ForgotModal';

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    forgotWatcher,
  },
  dispatch,
);

const mapStateToProps = state => ({
  // errors: getForgotError(state),
  // success: getForgotSuccess(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotModal);
