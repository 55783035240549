import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      // Fallback UI if an error occurs
      return (
        <div>
          <h2>Oh-no! Something went wrong</h2>
          <p className="red">{error.toString()}</p>
          <div>Component Stack Error Details: </div>
          <p className="red">{errorInfo.componentStack}</p>
        </div>
      );
    }
    // component normally just renders children
    return children;
  }
}
