import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: [],
  isLoading: false,
  errors: {},
  isCollapsedMenuBar: false,
  isLoadingUpdate: false,
  isLoadingChange: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileWatcher: (state) => {
      state.isLoading = true;
    },
    profileSuccess: (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    },
    profileFailed: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    collapseMenuBar: (state) => {
      state.isCollapsedMenuBar = !state.isCollapsedMenuBar;
    },
    updateProfile: (state) => {
      state.isLoadingUpdate = true;
    },
    updateSuccess: (state, action) => {
      state.isLoadingUpdate = false;
      state.profile = action.payload;
    },
    updateFailed: (state) => {
      state.isLoadingUpdate = false;
    },
    changePassword: (state) => {
      state.isLoadingChange = true;
    },
    changePasswordSuccess: (state) => {
      state.isLoadingChange = false;
    },
  },
});

export const {
  profileWatcher,
  profileSuccess,
  profileFailed,
  collapseMenuBar,
  updateProfile,
  updateSuccess,
  updateFailed,
  changePassword,
  changePasswordSuccess,
} = profileSlice.actions;

export default profileSlice.reducer;
