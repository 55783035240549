import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signupWatcher } from 'slices/signup/signupSlice';

import RegisterModal from './RegisterModal';

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    signupWatcher,
  },
  dispatch,
);

const mapStateToProps = state => ({
  errors: state.signup.errors,
  loading: state.signup.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterModal);
