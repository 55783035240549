/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { compose } from 'redux';
// import { reduxForm } from 'redux-form';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  withStyles,
  IconButton,
  Button,
  Checkbox,
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import { get } from 'lodash';
import classNames from 'classnames';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { func, string, objectOf, any, bool } from 'prop-types';
import {
  validateRegisterEmployerEmail,
  validateEmailCommon,
} from '../../../utils/help';
import {
  ModalSection,
  RightSection,
  FormContainer,
  FormSection,
  FieldAlignCenter,
  FieldAlignCenterCheckbox,
  CloseIconWrap,
} from '../styled';
import { TextCheckbox, TextLink } from '../../Common/CenterLineText/styled';
import { SnipperLoading } from '../../Common/index';
import LeftSection from './LeftSection';
import customContent from './customContent';

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
    width: '100%',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 300,
    width: 300,
  },
});

class RegisterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      email: '',
      showPassword1: false,
      showPassword2: false,
      confirm: false,
      subscribe: true,
      redirect: false,
      isConfirmSocial: true,
      isCheckValidate: false,
      errorEmail: '',
      errorPassword1: '',
      errorPassword2: '',
    };
  }

  submitSocialButton = (onClickSocialRegister, type) => () => {
    const { confirm } = this.state;
    if (confirm) {
      this.setState({ isConfirmSocial: true });
      onClickSocialRegister();
    } else {
      this.setState({ [type]: true });
    }
  };

  handleChange = prop => event => {
    if (prop === 'confirm' || prop === 'subscribe') {
      this.setState({
        [prop]: event.target.checked,
        isConfirmSocial: true,
      });
    } else {
      this.setState({ [prop]: event.target.value });
    }
    if (prop === 'email' && event.target.value) {
      this.setState({ errorEmail: '' });
    }
    if (prop === 'password1' && event.target.value) {
      this.setState({ errorPassword1: '' });
    }
    if (prop === 'password2' && event.target.value) {
      this.setState({ errorPassword2: '' });
    }
  };

  setRedirect = () => this.setState({ redirect: true });

  handleTermsAndConditions = () => {
    const { redirect } = this.state;
    if (redirect) {
      this.setState({ redirect: false });
      return <Redirect to="/terms&conditions" />;
    }
    return '';
  };

  handleClickShowPassword = () =>
    this.setState(state => ({ showPassword1: !state.showPassword1 }));

  handleClickShowConfirmPassword = () =>
    this.setState(state => ({
      showPassword2: !state.showPassword2,
    }));

  handleSubmit = () => {
    const { signupWatcher } = this.props;
    const {
      password1,
      password2,
      email,
      // subscribe,
    } = this.state;
    const isValidRegister = this.isValidRegister();
    this.setState({ isCheckValidate: true });
    if (isValidRegister) {
      // setEmailRegister(email);
      const data = {
        email,
        password1,
        password2,
      };
      signupWatcher(data);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { success } = this.props;
    if (
      nextProps.success !== success &&
      nextProps.success === 'Verification e-mail sent.'
    ) {
      this.setState({
        password1: '',
        password2: '',
        email: '',
        confirm: false,
        subscribe: true,
      });
    }
  }

  isValidRegister = () => {
    const { email, password1, password2, userType } = this.state;
    const { t } = this.props;
    let isValid = true;
    if (!email) {
      this.setState({ errorEmail: t('msg_empty_email') });
      isValid = false;
    }
    if (!password1) {
      this.setState({ errorPassword1: t('msg_empty_password') });
      isValid = false;
    }
    if (!password2) {
      this.setState({
        errorPassword2: t('msg_confirm_password_empty'),
      });
      isValid = false;
    }

    if (password2 !== password1 && password1 && password2) {
      this.setState({
        errorPassword2: t('msg_confirm_pwd_not_mactch_pwd'),
      });
      isValid = false;
    }
    // validate email for employer
    if (email && userType === 0 && !validateRegisterEmployerEmail(email)) {
      this.setState({ errorEmail: t('invalid_email') });
      isValid = false;
    }

    // validate email for candidate
    if (email && userType === 1 && !validateEmailCommon(email)) {
      this.setState({ errorEmail: t('invalid_email') });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  };

  handleCloseLoginDialog = () => {
    const { handleClose } = this.props;
    this.setState({ errorEmail: '', errorPassword1: '', errorPassword2: '' });
    handleClose();
  };

  render() {
    const {
      open,
      classes,
      errors,
      handleOpenLoginModal,
      t,
      loading,
      isCandidate,
      modalType,
      // registerForm,
      // confirm,
    } = this.props;
    const {
      email,
      showPassword1,
      password1,
      showPassword2,
      password2,
      subscribe,
      confirm,
      isConfirmSocial,
      userType,
      errorEmail,
      errorPassword1,
      errorPassword2,
    } = this.state;
    // const registerFormVal = get(registerForm, 'RegisterForm.values.userRole');
    // const userTypeIsEmpty = registerFormVal === undefined;
    const isNotAbleToSubmit = !confirm;
    let tncPath;
    switch (userType) {
      case 0:
        tncPath = '/terms&conditions/employer';
        break;

      case 1:
        tncPath = '/terms&conditions/candidate';
        break;

      default:
        tncPath = null;
        break;
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => this.handleCloseLoginDialog()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalSection className={classes.paper}>
          <CloseIconWrap>
            <IconButton
              style={{ outline: 'none' }}
              onClick={() => this.handleCloseLoginDialog()}
            >
              <Close />
            </IconButton>
          </CloseIconWrap>
          <div className="max-h-600 overflow-auto max-w-1050">
            <Grid container style={{ display: 'unset' }}>
              <Grid item>
                <SnipperLoading
                  loading={loading}
                  cover
                  css="background-color: rgb(255,255,255,0.8)"
                />
              </Grid>
              <Grid item style={{ display: 'flex', minHeight: 500 }}>
                <Grid item md={12}>
                  <LeftSection
                    isCandidate={isCandidate}
                    modalType="landing"
                  />
                </Grid>
                <Grid item md={12} style={{ width: '100%' }}>
                  <RightSection>
                    <h1 className="c:#283583 fs-24 font-bold text-center -mb-15 mt-15">
                      {t('register')}
                    </h1>
                    <FormSection className="pt-20">
                      <FormContainer>
                        {modalType === 'personality' && (
                          <FieldAlignCenter onlyMobile>
                            {customContent.personality.words({
                              color: '#4c4c4c',
                              textAlign: 'center',
                              margin: '8px',
                            })}
                          </FieldAlignCenter>
                        )}
                        <TextField
                          id="outlined-adornment-email"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          margin="dense"
                          variant="outlined"
                          type="text"
                          label="Email"
                          value={email}
                          helperText={get(errors, 'email[0]') || errorEmail}
                          error={
                            !!(get(errors, 'email', []).length > 0 ||
                              errorEmail !== '')
                          }
                          onChange={this.handleChange('email')}
                        />
                        <TextField
                          id="outlined-adornment-password1"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          margin="dense"
                          variant="outlined"
                          type={showPassword1 ? 'text' : 'password'}
                          label={t('login_modal.password')}
                          value={password1}
                          helperText={
                            get(errors, 'password1[0]') || errorPassword1
                          }
                          error={
                            !!(get(errors, 'password1[0]', []).length > 0 ||
                              errorPassword1 !== '')
                          }
                          onChange={this.handleChange('password1')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {showPassword1 ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          id="outlined-adornment-confirm-password"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          margin="dense"
                          variant="outlined"
                          type={showPassword2 ? 'text' : 'password'}
                          label={t('login_modal.confirm_password')}
                          value={password2}
                          helperText={
                            get(errors, 'password2[0]') || errorPassword2
                          }
                          error={
                            !!(get(errors, 'password2[0]', []).length > 0 ||
                              errorPassword2)
                          }
                          onChange={this.handleChange('password2')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                >
                                  {showPassword2 ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FieldAlignCenterCheckbox>
                          <Checkbox
                            checked={subscribe}
                            onChange={this.handleChange('subscribe')}
                            value="subscribe"
                            style={{ padding: 5 }}
                          />
                          <TextCheckbox>
                            {t('login_modal.subscribe_newsletter')}
                          </TextCheckbox>
                        </FieldAlignCenterCheckbox>

                        <FieldAlignCenterCheckbox>
                          <Checkbox
                            checked={confirm}
                            onChange={this.handleChange('confirm')}
                            value="confirm"
                            style={{ padding: 5 }}
                          />
                          <TextCheckbox style={{ display: 'flex' }}>
                            {t('login_modal.agree_to')}
                            {tncPath ? (
                              <a
                                href={tncPath}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  cursor: 'pointer',
                                  color: 'blue',
                                  textDecoration: 'none',
                                  paddingLeft: 5,
                                }}
                              >
                                {t('login_modal.term_and_condition')}
                              </a>
                            ) : (
                              t('login_modal.term_and_condition')
                            )}
                            {this.handleTermsAndConditions()}
                          </TextCheckbox>
                        </FieldAlignCenterCheckbox>
                        {!isConfirmSocial && (
                          <p style={{ paddingLeft: 17, color: 'red' }}>
                            Please read and tick the terms and conditions box
                            before proceeding
                          </p>
                        )}
                        <FieldAlignCenter>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.margin}
                            onClick={this.handleSubmit}
                            disabled={isNotAbleToSubmit}
                          >
                            {t('register')}
                          </Button>
                        </FieldAlignCenter>

                        <FieldAlignCenter>
                          <TextLink onClick={handleOpenLoginModal}>
                            {t('login_modal.have_an_acc')}
                          </TextLink>
                        </FieldAlignCenter>
                      </FormContainer>
                    </FormSection>
                  </RightSection>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ModalSection>
      </Modal>
    );
  }
}

RegisterModal.propTypes = {
  registerForm: objectOf(any).isRequired,
  setEmailRegister: func.isRequired,
  signupWatcher: func.isRequired,
  signupLinkedinWatcher: func.isRequired,
  signupFacebookWatcher: func.isRequired,
  success: string.isRequired,
  handleClose: func.isRequired,
  open: bool.isRequired,
  classes: objectOf(any),
  errors: objectOf(any),
  handleOpenLoginModal: func.isRequired,
  t: func.isRequired,
  isCandidate: bool.isRequired,
  loading: any.isRequired,
  modalType: string,
};

RegisterModal.defaultProps = {
  classes: {},
  errors: {},
};

// const RegisterModalForm = reduxForm({
//   form: 'RegisterForm',
// })(RegisterModal);

export default compose(
  withStyles(styles),
  withTranslation(),
)(RegisterModal);
