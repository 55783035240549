// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import { isEqual } from 'lodash';
import MediaQuery from 'react-responsive';
import { withStyles, Card, Modal, Grid, IconButton } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { deepOrange } from '@material-ui/core/colors';

// assets
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/map-marker-alt-light.svg';
import { ReactComponent as SkypeIcon } from 'assets/icons/skype.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone-line.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';

import { RenderAvatar } from 'components/Common';
import {
  AvatarWrap,
  ProfileInfo,
  DesktopProfileLabel,
  // MobileIcon,
  Icon,
} from 'components/Common/DetailPages/styled';
import ModalEditPassword from './EditPassword';
import ModalEditAvatar from './UploadAvatar';
import EditProfile from './EditProfile';

class GeneralInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dialogUploadAvatar: false,
      hasSelect: false,
      isOpenEditPasswordModal: false,
      score: 0,
    };
  }

  handleCloseProgressModal = () => {
    this.setState({ isOpenProgressModal: false });
  };

  toggleUploadAvatar = () => {
    const { dialogUploadAvatar } = this.state;
    this.setState({ dialogUploadAvatar: !dialogUploadAvatar });
  };

  handleOpenEditPasswordModal = () => {
    this.setState({ isOpenEditPasswordModal: true });
  };

  handleCloseEditPasswordModal = () => {
    this.setState({ isOpenEditPasswordModal: false });
  };

  render() {
    const { profile, classes, t } = this.props;
    const { address, first_name, last_name, email, mobile_number, skype } = profile || {};
    const { dialogUploadAvatar, isOpenEditPasswordModal } = this.state;

    return (
      <>
        <Card style={{ paddingBottom: 15 }}>
          <Grid container className="ml-20 mr-20">
            <Grid xs={6} md={2} xl={2}>
              <AvatarWrap fitCover>
                <RenderAvatar
                  profile={profile}
                  classes={classes}
                  cursor
                  handleClick={this.toggleUploadAvatar}
                />
                {/* {avatar ? (
                  <img
                    src={avatar}
                    alt={`${first_name} ${last_name}`}
                    onClick={this.toggleUploadAvatar}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                    <Avatar
                      className={classes.mainAvatar}
                      onClick={this.toggleUploadAvatar}
                      style={{ cursor: 'pointer' }}
                    >
                      {first_name}
                    </Avatar>
                  )} */}
              </AvatarWrap>
              <ProfileInfo style={{ textAlign: 'center', paddingTop: 10 }}>
                <Icon>
                  <LocationIcon />
                </Icon>
                {address || t('profile.header.no_info')}
              </ProfileInfo>
              <ModalEditAvatar
                isOpenModal={dialogUploadAvatar}
                onCloseModal={this.toggleUploadAvatar}
                onAvatarChange={this.getAvatar}
              />
            </Grid>

            <MediaQuery maxWidth={991}>
              <Grid xs={6} style={{ textAlign: 'left', marginTop: 20 }}>
                <h1
                  className="inline-block fs-18 font-semibold mb-0 max-w-calc-48 "
                >
                  {`${first_name} ${last_name}`}
                </h1>
                <EditProfile profile={profile} style={{ marginTop: -15 }} />
                <ProfileInfo>{t('partner')}</ProfileInfo>
              </Grid>
            </MediaQuery>

            <Grid xl={10} md={10} className="text-left pl-15">
              <MediaQuery minWidth={992}>
                <div
                  style={{
                    display: 'flex',
                    maxWidth: '100%',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  <h1
                    className="inline-block fs-24 font-semibold mb-0 max-w-calc-48 "
                  >
                    {`${first_name} ${last_name}`}
                  </h1>
                  <EditProfile profile={profile} style={{ marginLeft: 15, marginTop: 4 }} />
                </div>
                <ProfileInfo className="fs-18 md:fs-20">{t('partner')}</ProfileInfo>
                {/* <ProfileInfo className="fs-18 md:fs-20 pt-10">{t('dashboard_page.employer_list.company')} : VN Company</ProfileInfo> */}
              </MediaQuery>
              <Grid container>
                <Grid xs={6} lg={4} xl={4} >
                  <ProfileInfo className="fs-14 md:fs-16 ">
                    <DesktopProfileLabel />
                    <EmailIcon className="w-14 h-14 md:h-18 md:w-18" /> : {email || t('profile.header.no_info')}
                  </ProfileInfo>
                </Grid>
                <Grid xs={6} lg={4} xl={4} className="pl-5 lg:pl-0">
                  <DesktopProfileLabel />
                  <ProfileInfo className="fs-14 md:fs-16 ">
                    <PhoneIcon className="w-14 h-14 md:h-18 md:w-18" /> : {mobile_number || t('profile.header.no_info')}
                  </ProfileInfo>
                </Grid>
                <Grid xs={6} lg={4} xl={4}>
                  <DesktopProfileLabel />
                  <ProfileInfo className="fs-14 md:fs-16 ">
                    <SkypeIcon className="w-14 h-14 md:h-18 md:w-18" /> : {skype || t('profile.header.no_info')}
                  </ProfileInfo>
                </Grid>
                <Grid xs={6} lg={4} xl={4} className="pl-5 lg:pl-0">
                  <DesktopProfileLabel />
                  <ProfileInfo>
                    <KeyIcon className="w-14 h-14 md:h-18 md:w-18" /> :    ********
                    <IconButton
                      className="lg:-mt-5 lg:p-12 -mt-16 p-8 outline-none w-14 h-14"
                      onClick={this.handleOpenEditPasswordModal}
                    >
                      <Create className="w-13 h-13" />
                    </IconButton>
                  </ProfileInfo>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpenEditPasswordModal}
          onClose={this.handleCloseEditPasswordModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '25px',
          }}
        >
          {isOpenEditPasswordModal ? (
            <ModalEditPassword onCloseModal={this.handleCloseEditPasswordModal} email={email} />
          ) : null}
        </Modal>
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  dropdown: {
    width: '185px',
    margin: '0em 0em 0em -2em',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
  },
  chip: {
    margin: theme.spacing.unit * 1,
  },
  card: {
    minWidth: 275,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 1,
    '@media (max-width: 780px)': {
      padding: 0,
    },
  },
  topTypography: {
    fontSize: 14,
    textAlign: 'left',
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
  },
  nameTopTypography: {
    fontWeight: 900,
    textAlign: 'left',
    wordBreak: 'break-word',
    paddingTop: theme.spacing.unit * 1,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    margin: 10,
  },
  mainAvatar: {
    margin: 10,
    width: '100%',
    height: '100%',
    color: '#fff',
    backgroundColor: deepOrange[600],
  },
  notifAvatar: {
    marginLeft: 10,
    width: 48,
    height: 48,
    color: '#fff',
    backgroundColor: deepOrange[600],
  },
  notifProfile: {
    marginBottom: 5,
  },
  btnEditProfile: {},
  smallCustomIcon: {
    fontSize: 15,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileCompletion: {
    position: 'relative',
    width: '100px',
    height: '100px',
    // marginLeft: "18px",
    margin: '0 auto',
  },
  progress: {
    display: 'inline-block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100px',
    height: '100px',
  },
  textField: {
    width: 280,
  },
  locationTextField: {
    width: 200,
    padding: '0em 1em 0em 0em',
    margin: '0em 0em 0em -2em',
  },
  mediumTextField: {
    width: 200,
  },
  shortTextField: {
    width: 140,
  },
  positionField: {
    marginLeft: '12px',
  },
  desInfoAvatar: {
    fontSize: 12,
  },
});

// Props type
GeneralInfo.propTypes = {
  profile: PropTypes.shape({
    pk: PropTypes.string,
    email: PropTypes.string,
    // first_name: PropTypes.string,
    // last_name: PropTypes.string,
    type: PropTypes.number,
    skype: PropTypes.string,
    mobile_number: PropTypes.string,
    socket: PropTypes.string,
    employer: PropTypes.object,
  }),
  classes: PropTypes.object,
};

GeneralInfo.defaultProps = {
  pk: '',
  email: '',
  first_name: '',
  last_name: '',
  type: 0,
  skype: '',
  mobile_number: '',
  socket: '',
  employer: {},
};
export default withTranslation()(withStyles(styles)(GeneralInfo));
