import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'slices/login/loginSlice';
import { profileWatcher } from 'slices/profile/profileSlice';

import Topbar from './Topbar';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      push,
      // openRegisterModal,
      // openLoginModal,
      profileWatcher,
      handleRedirect: url => push(url),
    },
    dispatch,
  );

const mapStateToProps = state => ({
  isLogin: state.login.isLogin,
  profile: state.profile.profile,
  // successRegister: getRegisterSuccess(state),
  // successForgot: getForgotSuccess(state),
  // detail: getCandidateDetail(state),
  // profile: getProfile(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topbar);
