import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles, Paper } from '@material-ui/core';

// Components Setting
import DashboardLayout from 'components/Layout';
import SnipperLoading from '../../components/Common/SnipperLoading';

// Section Tab
import AboutInfo from './AboutInfo';
import GeneralInfo from './GeneralInfo';
import Payment from './Payment';
import TabSetting from './TabSetting/Tab';
import TabContainer from './TabSetting/Tab/Container';
import WrapperContainer from './TabSetting/WrapperContainer';


import { Container } from './Styled';
// import TabContainer from '../../components/Setting/Tab/Container';
// import {
//  updateSelectedTab } from '../../store/sagas/profileSaga/actions';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  typography: {
    paddingBottom: theme.spacing.unit * 2,
    fontWeight: 'bold',
  },
  paper: {
    margin: '0 8px',
    padding: '16px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: '0 5px 10px 0 #d6d6d680',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 3,
      margin: '0 8px',
    },
  },
  chip: {
    margin: theme.spacing.unit * 1,
  },
  topTypography: {
    paddingTop: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 1,
  },
  buttonMenu: {
    outline: 'none !important',
  }
  // nameTopTypography: {
  //   fontWeight: 900,
  //   textAlign: 'left',
  //   paddingTop: theme.spacing.unit * 1,
  //   paddingLeft: theme.spacing.unit * 2,
  // },
  // avatar: {
  //   margin: 10,
  // },
  // mainAvatar: {
  //   margin: 10,
  //   width: 130,
  //   height: 130,
  //   color: '#fff',
  //   backgroundColor: deepOrange[600],
  // },
  // notifAvatar: {
  //   marginLeft: 10,
  //   width: 48,
  //   height: 48,
  //   color: '#fff',
  //   backgroundColor: deepOrange[600],
  // },
  // notifProfile: {
  //   marginBottom: 5,
  // },
  // btnEditProfile: {},
  // smallCustomIcon: {
  //   fontSize: 15,
  // },
  // details: {
  //   display: 'flex',
  //   flexDirection: 'column',
  // },
  // indicator: {
  //   backgroundColor: 'blue',
  // },
  // textField: {
  //   width: 280,
  // },
  // longTextField: {
  //   width: 588,
  // },
  // shortTextField: {
  //   width: 84,
  // },
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogUploadImage: false,
      name: '',
      address: '',
      industry: '',
      no_of_employee: '',
      website: '',
      facebook: '',
      instagram: '',
      description: '',
      benefits: '',
      minBenefit: '',
      maxBenefit: '',
      deletingImage: '',
      Allstate: true,
      selectedTab: 0,
    };
  }

  componentDidMount() {
    // const {
    //   selectedTab,
    //   updateSelectedTab,
    //   requestPosition,
    //   requestCompanyDetail,
    //   fetchOnboardData,
    // } = this.props;
    // updateSelectedTab(selectedTab || 0);
    // requestPosition();
    // fetchOnboardData();
    // if (employer && employer.company) {
    //   requestCompanyDetail({
    //     url: '',
    //     id: employer.company.id,
    //   });
    // }
  }

  // componentDidUpdate(preProps) {
  //   const { profile: preProfile } = preProps;
  //   const { profile } = this.props;
  //   if (!isEqual(profile, preProfile)) {
  //     this.setState({ deletingImage: false });
  //     this.forceUpdate();
  //   }
  // }

  componentWillUnmount() {
    // const { updateSelectedTab } = this.props;
    // updateSelectedTab(0);
  }

  handleChange = (id) => {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  };

  handleChangeInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  toggleUploadImage = () => {
    const { dialogUploadImage } = this.state;
    this.setState({ dialogUploadImage: !dialogUploadImage });
  };

  // getImage = () => {
  //   const {
  //     requestCompanyDetail,
  //     profile: { employer },
  //   } = this.props;
  //   requestCompanyDetail({
  //     url: '',
  //     id: employer.company.id,
  //   });
  // };

  // handleDeleteCompanyImage(id) {
  //   const { removeImage } = this.props;
  //   this.setState({ deletingImage: true });
  //   removeImage(id);
  // }

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
    // const { updateSelectedTab } = this.props;
    // updateSelectedTab(newValue);
  };

  render() {
    const {
      classes,
      profile,
      positions,
      loading,
      t,
    } = this.props;
    const {
      deletingImage,
      selectedTab,
    } = this.state;
    const title = t('naz_setting.title');
    return (
      <DashboardLayout title={title} topTypography="Settings" active="Settings">
        <SnipperLoading loading={loading && deletingImage} cover text={t('naz_setting.deleting_image')} />
        <GeneralInfo
          profile={profile}
          positions={positions}
        // onPositionChange={this.getPosition}
        />
        <Container className={`${classes.root} box-shadow-2`}>
          <TabSetting position="static" value={selectedTab} onChange={this.handleChangeTab} />
          <WrapperContainer id={0} selectedTab={selectedTab}>
            <TabContainer>
              <Paper className={classes.paper}>
                <AboutInfo profile={profile} />
              </Paper>
            </TabContainer>
          </WrapperContainer>
          <WrapperContainer id={1} selectedTab={selectedTab}>
            <TabContainer>
              <Paper className={classes.paper}>
                <Payment profile={profile} />
              </Paper>
            </TabContainer>
          </WrapperContainer>
        </Container>
      </DashboardLayout>
    );
  }
}

Settings.propTypes = {
  profile: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles),
)(Settings);
