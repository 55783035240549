import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
  Typography,
  Modal,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
  Fab,
} from '@material-ui/core';
import { changePassword } from 'slices/profile/profileSlice';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { toast } from 'react-toastify';
// import Services from 'services';
import { toastConfig } from '../../../../utils/config';

const initialState = {
  password: '',
  newpassword: '',
  confrim_password: '',
  showOldpassword: false,
  showPassword1: false,
  showPassword2: false,
};

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    outline: 'none',
  },
  textField: {
    marginBottom: 3,
  },
  inputSize: {
    height: 6,
    fontSize: '14px',
  },
  modalHeader: {
    position: 'relative',
    paddingBottom: 8,
  },
  conts: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-5px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
});

class FormEditProfile extends PureComponent {
  state = initialState;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isRequesting, onCloseModal } = this.props;
    if (isRequesting && !nextProps.isRequesting) {
      onCloseModal();
    }
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowOldpassword = () => {
    this.setState(state => ({ showOldpassword: !state.showOldpassword }));
  };

  handleClickShowNewPassword = () => {
    this.setState(state => ({ showPassword1: !state.showPassword1 }));
  };

  handleClickShowPasswordConfrim = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };

  handleSubmit = () => {
    const { changePassword, t } = this.props;
    const { newpassword, confrim_password, password } = this.state;
    if (newpassword !== confrim_password) {
      toast.error(t('profile.password.pw_not_match'), toastConfig);
    } else if (newpassword && confrim_password && newpassword.length < 6) {
      toast.error(t('profile.password.pw_length_more_than_6'), toastConfig);
    } else {
      const paramsPassword = {
        old_password: password,
        new_password1: newpassword,
        new_password2: confrim_password,
      };
      changePassword(paramsPassword);
    }
  };

  render() {
    const {
      password,
      newpassword,
      confrim_password,
      showPassword1,
      showOldpassword,
      showPassword2,
    } = this.state;
    const { onCloseModal, classes, isRequesting, t } = this.props;

    return (
      <div className={classes.paper}>
        <Modal open={isRequesting} disableBackdropClick disableEscapeKeyDown>
          <div className="flex items-center justify-center h-full c:white">
            <CircularProgress color="inherit" />
            <Typography variant="h6" id="modal-title">
              {t('profile.password.edit_pw')}
            </Typography>
          </div>
        </Modal>

        <div className="w-360 max-h-600 overflow-auto max-w-1050">
          <div className={classes.modalHeader}>
            <h1 className="c:black fs-20 font-bold text-center -mb-15 mt-15">{t('profile.password.edit_pw')}</h1>
          </div>

          <div className="modal-body">
            <div className={classes.conts}>
              {/* Old Password */}
              <TextField
                fullWidth
                type={showOldpassword ? 'text' : 'password'}
                id="password"
                label={t('profile.password.old_pw')}
                className={classes.textField}
                value={password}
                onChange={this.handleChange('password')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowOldpassword}>
                        {showOldpassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.inputSize },
                }}
              />
            </div>
            <div className={classes.conts}>
              {/* New Password */}
              <TextField
                fullWidth
                type={showPassword1 ? 'text' : 'password'}
                id="newpassword"
                label={t('profile.password.new_pw')}
                className={classes.textField}
                value={newpassword}
                onChange={this.handleChange('newpassword')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword}>
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.inputSize },
                }}
              />
            </div>

            {/* Confrim Password */}
            <div className={classes.conts}>
              <TextField
                fullWidth
                type={showPassword2 ? 'text' : 'password'}
                id="confrim_password"
                label={t('profile.password.confirm_pw')}
                className={classes.textField}
                value={confrim_password}
                onChange={this.handleChange('confrim_password')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPasswordConfrim}>
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.inputSize },
                  minLength: 6,
                }}
              />
            </div>
          </div>

          <div className={classes.modalFooter}>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Save"
              style={{
                width: 100,
                height: 45,
                borderRadius: 30,
                margin: 10,
                backgroundColor: '#bcd9f9',
                outline: 'none',
              }}
              onClick={onCloseModal}
            >
              {t('onboarding.cancel')}
            </Fab>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Save"
              style={{
                width: 100,
                height: 45,
                borderRadius: 30,
                margin: 10,
                backgroundColor: '#f9bcbc',
                outline: 'none',
              }}
              onClick={this.handleSubmit}
            >
              {t('profile.password.save')}
            </Fab>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changePassword: bindActionCreators(changePassword, dispatch),
});

const mapStateToProps = state => ({
  isRequesting: state.profile.isLoadingChange,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles),
)(FormEditProfile);
