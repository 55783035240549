import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Footer = styled.div`
  position: relative;
  z-index: 0;
  background-color: #eb5b49;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px 20px;
  @media only screen and (min-width: 768px) {
    text-align: left;
  }
  @media only screen and (min-width: 1024px) {
    padding: 10px 50px 30px;
  }
`;

export const LogoFooterContainer = styled.div`
  cursor: pointer;
`;

export const FirstColumnContent = styled.div`
  @media only screen and (min-width: 768px) {
    padding-right: 30px;
  }
`;

export const FooterMenuText = styled.div`
  color: #ffffff;
  font-weight: 400;
  margin-top: 15px;
`;

export const FooterMenuLink = styled(FooterMenuText)`
  cursor: pointer;
`;

export const FooterMenuTitle = styled.div`
  color: #ffffff;
  font-weight: 600;
  line-height: 20px;
  margin-top: 50px;
`;

export const SocialSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const ContainerIcon = styled.div`
  padding: 10px;
`;

export const ContainerGrid = styled(Grid)`
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const ItemGrid = styled(Grid)`
  padding: 0px 15px;
`;
