import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tnc: [],
  isLoading: false,
};

const tncSlice = createSlice({
  name: 'tnc',
  initialState,
  reducers: {
    tncWatcher: (state) => {
      state.isLoading = true;
    },
    tncSuccess: (state, action) => {
      state.isLoading = false;
      state.tnc = action.payload.tnc;
    },
    tncFailed: (state) => {
      state.isLoading = false;
      state.tnc = [];
    },
  },
});

export const { tncWatcher, tncSuccess, tncFailed } = tncSlice.actions;

export default tncSlice.reducer;
