import React, { useEffect } from 'react';
import { func, any } from 'prop-types';
import { Grid } from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import { withTranslation } from 'react-i18next';
import { ReactComponent as PartnerTNC } from 'assets/images/TNC.svg';
import { Content, Title, SubContent, Paragraph, GridContainer, CenterGridContainer } from './styled';
import { Footer, Topbar } from '../../components/LandingPage';

const TermsAndConditions = props => {
  const {
    tncWatcher,
    tnc = '',
    t,
  } = props;

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    tncWatcher({ lang });
  }, [tncWatcher]);

  return (
    <div className="ld-container">
      <Topbar />
      <GridContainer>
        <CenterGridContainer item sm={12} md={4} className="flex flex-column justify-content-center">
          <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated zoomIn">
            <Title>{t('tnc_title')}</Title>
          </InViewMonitor>
        </CenterGridContainer>
        <Grid item sm={12} md={8}>
          <InViewMonitor classNameNotInView="vis-hidden" classNameInView="animated fadeInUp">
            <PartnerTNC className="img-fluid first-image" />
          </InViewMonitor>
        </Grid>
      </GridContainer>
      <Content>
        <SubContent>
          <Paragraph>
            <div dangerouslySetInnerHTML={{ __html: tnc }} />
          </Paragraph>
        </SubContent>
      </Content>
      <Footer />
    </div>
  );
};

TermsAndConditions.propTypes = {
  tncWatcher: func.isRequired,
  tnc: any.isRequired,
  t: func.isRequired,
};

export default withTranslation()(TermsAndConditions);
