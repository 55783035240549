import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingWatcher } from 'slices/onboarding/onboardingSlice';
import { push } from 'connected-react-router';

import basicPage from './basicPage';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onboardingWatcher,
      goDashboard: () => push('/dashboard'),
    },
    dispatch,
  );

const mapStateToProps = state => ({
  loading: state.onboarding.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(basicPage);
