import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginWatcher, resendConfirmation } from 'slices/login/loginSlice';

import LoginModal from './LoginModal';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resendConfirmation,
      loginWatcher,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  errors: state.login.errors,
  // success: getLoginSuccess(state),
  loading: state.login.isLoading,
  // loadingSocial: getLoadingRegister(state),
  loadingVerify: state.login.isLoadingResend,
  // rememberEmail: getRememberEmail(state),
  showReverified: state.login.isShowReverified,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginModal);
