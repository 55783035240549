import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { TextFieldCustom as TextField } from './styled';

/**
 * @param {String} id id textfield
 * @param {Object} icon Icon in item select
 * @param {String} error has error
 * @param {String} value should contain detail location
 * @param {String} label contain label for textfield
 * @param {String} placeholder placeholder textfield
 * @param {function} onSelect function handle on select in dropdown
 * @param {function} onChange function handle change value locations
 * @param {Boolean} isFullWidth
 * @param {Object} styleWrapperItem should contains classname style material ui)
 * @param {Object} classes contain object style material ui
 */
export default ({
  icon,
  error,
  helperText = 'this field is required',
  searchOptions,
  value,
  onChange,
  onSelect,
  classInputSize,
  placeholder,
  label,
  isFullWidth,
  id,
  classes = '',
  styleWrapperItem = {},
  ...rest
}) => {
  const autoCompleteProps = {
    value,
    ...(searchOptions ? { searchOptions } : null),
    onChange,
    onSelect,
    ...rest,
  };
  return (
    <PlacesAutocomplete {...autoCompleteProps}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <TextField
            id={id}
            label={label}
            fullwidth={isFullWidth}
            margin="normal"
            variant="outlined"
            InputProps={{ classes: { input: classInputSize } }}
            InputLabelProps={{ shrink: true }}
            value={value}
            helperText={error && helperText}
            error={error}
            {...getInputProps({
              placeholder,
              className: 'location-search-input',
            })}
          />
          <Paper className={classes} square>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <MenuItem component="div">
                    {/* <MdPlace
                          style={{ paddingRight: 10, fontSize: 30 }}
                          /> */}
                    {icon}
                    <div
                      style={{
                        width: '700px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {suggestion.description}
                    </div>
                  </MenuItem>
                </div>
              );
            })}
          </Paper>
        </>
      )}
    </PlacesAutocomplete>
  );
};
