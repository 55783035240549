import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const SubTitle = styled.h2`
  width: 550px;
  font-size: 24px;
  line-height: 1.46;
  text-align: center;
  color: #818182;

  @media only screen and (max-width: 1444px) {
    width: 477px;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (max-width: 770px) {
    width: auto;
    font-size: 18px;
  }

  @media only screen and (max-width: 400px) {
    width: 300px;
    font-size: 18px;
  }
`;

export const Content = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Title = styled.h1`
  width: 550px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-align: left;
  color: #000000;
  margin-left: 150px;


  @media only screen and (max-width: 1444px) {
    width: 500px;
    font-size: 60px;
    padding-right: 50px;
  }

  @media only screen and (max-width: 1200px) {
    margin-left: 200px;
  }

  @media only screen and (max-width: 960px) {
    width: auto;
    font-size: 32px;
    text-align: center;
    margin: auto;
    padding: 0;
    margin-top: 30px;
    margin-left: 0;
  }
`;

export const SubContent = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Paragraph = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  color: #00000;
  padding-top: 1rem;

  @media only screen and (max-width: 770px) {
    width: auto;
    font-size: 10px;
  }

  @media only screen and (max-width: 1444px) {
    font-size: 18px;
    margin-left: 45px;
    margin-right: 80px;
  }
`;

export const GridContainer = styled(Grid)`
  margin-top: 3rem !important;
  @media only screen and (min-width: 769px) {
    display: flex;
  }
`;
export const CenterGridContainer = styled(Grid)`
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
`;
