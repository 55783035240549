import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Button from '@material-ui/core/Button';
import { dashboardWatcher, analyticWatcher } from 'slices/dashboard/dashboardSlice';
import EmployerList from './EmployerList';
import ReferralBoard from './ReferralBoard';
import PaginationBar from './PaginationBar';
import ReferModal from './ReferModal';

const Main = (props) => {
  const {
    profile,
    getDashboard,
    getaAnalytic,
    loading,
    loadingAnalytic,
    employerList,
    employerCount,
    link,
    referralCode,
    t,
  } = props;
  const profileFirstName = _get(profile, 'first_name', '');
  const code = _get(profile, 'partner_code', '');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isOpenModalRefer, setIsOpenModalRefer] = useState(false);

  useEffect(() => {
    const defaultData = {
      limit,
      offset,
    };
    getDashboard(defaultData);
    getaAnalytic();
  }, [getDashboard, getaAnalytic, offset, limit]);

  const handleChangePage = (nextOffset, nextLimit) => {
    setOffset(nextOffset);
    setLimit(nextLimit);
    const nextData = {
      limit: nextLimit,
      offset: nextOffset,
    };
    getDashboard(nextData);
  };

  const openModalRefer = () => {
    setIsOpenModalRefer(true);
  };

  const toggleModalConfirm = () => {
    setIsOpenModalRefer(false);
  };

  return (
    <div className="p-20">
      <div className="flex mt-0 mb-0 lg:mr-20 lg:ml-20">
        <div className="text-left">
          <div style={{ fontWeight: 'bold' }}>{t('dashboard_page.welcom')} {profileFirstName}</div>
          <div style={{ fontWeight: 'bold' }}>{t('dashboard_page.partner_code')} {code}</div>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: 'auto',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #000000',
            padding: '12px 25px',
            outline: 'none',
          }}
          onClick={openModalRefer}
        >
          {t('dashboard_page.refer_client')}
        </Button>
      </div>
      <ReferralBoard loading={loadingAnalytic} link={link} referralCode={referralCode} />
      <EmployerList data={employerList} loading={loading} />
      {employerCount > 0 && (
        <PaginationBar
          offset={offset}
          limit={limit}
          isLoading={loading}
          data={employerList}
          dataSum={employerCount}
          requestData={handleChangePage}
        />
      )}
      <ReferModal
        open={isOpenModalRefer}
        handleClose={toggleModalConfirm}
        code={code}
      />
    </div>
  );
};

Main.propTypes = {
  profile: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getDashboard: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingAnalytic: PropTypes.bool.isRequired,
  employerList: PropTypes.object.isRequired,
  employerCount: PropTypes.number.isRequired,
  getaAnalytic: PropTypes.func.isRequired,
  link: PropTypes.object.isRequired,
  referralCode: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loading: state.dashboard.isLoading,
  loadingAnalytic: state.dashboard.isLoadingAnalytic,
  employerList: state.dashboard.employerList,
  employerCount: state.dashboard.employerCount,
  profile: state.profile.profile,
  link: state.dashboard.link,
  referralCode: state.dashboard.referralCode,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDashboard: dashboardWatcher,
      getaAnalytic: analyticWatcher,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Main);
