import React, { useState } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import EditBank from './EditBank';

const PaymentDetail = (props) => {
  const { profile: { bankdetail } } = props;
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenEditBank = () => {
    setIsOpenModal(true);
  };

  const handleCloseEditBank = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="pl-10 c:black flex">
        <Typography className="pb-16 font-bold fs-16 xl:fs-20" variant="h5" component="h3">
          {t('bank.bank_detail')}
        </Typography>
        <IconButton
          className="lg:-mt-5 lg:p-12 -mt-16 p-8 outline-none w-30 h-30"
          onClick={handleOpenEditBank}
        >
          <Create className="w-13 h-13" />
        </IconButton>
      </div>
      <div className="pl-40">
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.account_name')} : {bankdetail ? bankdetail.account_name : ''}
        </div>
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.bank_name')} : {bankdetail ? bankdetail.bank_branch_name : ''}
        </div>
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.bank_number')} : {bankdetail ? bankdetail.bank_account_number : ''}
        </div>
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.bank_address')} :  {bankdetail ? bankdetail.bank_branch_address : ''}
        </div>
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.country')} : {bankdetail ? bankdetail.country : ''}
        </div>
        <div className="fs-14 mt-15 c:#1a173b">
          {t('bank.swift_code')} : {bankdetail ? bankdetail.swift_code : ''}
        </div>
        <div className="fs-14 mt-60 mb-20 c:#1a173b">
          {t('terms')} :
        </div>
        <div
          className="fs-14  pr-0 c:#1a173b"
        >
          - {t('bank.term_1')}
        </div>
        <div
          className="fs-14  pr-0 c:#1a173b"
        >
          - {t('bank.term_2')}
        </div>
      </div>
      <EditBank
        isOpenModal={isOpenModal}
        onCloseModal={handleCloseEditBank}
        bankdetail={bankdetail}
      />
    </>
  );
};

export default PaymentDetail;
