import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  withStyles,
  IconButton,
  Button,
  Checkbox,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { validateEmailCommon } from 'utils/help';
import SnipperLoading from 'components/Common/SnipperLoading';
import {
  ModalSection,
  LoginModalContainer,
  FormContainer,
  FormSection,
  FieldAlignCenter,
  LeftLoginSection,
  RightLoginSection,
  RowContainer,
  RegisterNow,
  ForgotPasswordLink,
  CloseIconWrap,
} from '../styled';

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 300,
    width: 300,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  landing: {
    position: 'absolute',
    textAlign: 'center',
    padding: '0 15px 20px 15px',
    bottom: '0',
    left: 0,
    fontSize: 14,
  },
});

class LoginModal extends Component {
  constructor(props) {
    super(props);
    // const { rememberEmail } = this.props;
    this.state = {
      isAllowShowErrValidation: false,
      password: '',
      // email: 'rememberEmail === '' ? '' : rememberEmail',
      email: '',
      showPassword: false,
      // remember: rememberEmail !== '',
      remember: '',
    };
  }

  handleChange = prop => event => {
    const { target } = event;
    const eventValue = prop === 'remember' ? target.checked : target.value;
    this.setState({ [prop]: eventValue });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleSubmit = () => {
    const { loginWatcher } = this.props;
    const { password, email } = this.state;
    this.setState({ isAllowShowErrValidation: true });
    if (
      email &&
      email.length > 0 &&
      password &&
      password.length > 0 &&
      validateEmailCommon(email)
    ) {
      loginWatcher({ email, password });
    }
  };

  handleReverified = () => {
    const { resendConfirmation } = this.props;
    const { email } = this.state;
    resendConfirmation(email);
  };

  handleCloseLoginDialog = () => {
    const { handleClose } = this.props;
    this.setState({ isAllowShowErrValidation: false });
    handleClose();
  };

  render() {
    const {
      open,
      classes,
      errors,
      handleOpenForgotModal,
      handleOpenRegisterModal,
      isCandidate,
      loading,
      loadingVerify,
      t,
      showReverified,
      // reverified,
    } = this.props;
    const {
      email,
      showPassword,
      password,
      remember,
      isAllowShowErrValidation,
    } = this.state;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => this.handleCloseLoginDialog()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalSection className={classes.paper}>
          <SnipperLoading
            loading={loading || loadingVerify}
            text={loadingVerify ? t('login_modal.loading_verify') : `${t('login')}...`}
            cover
          />
          <CloseIconWrap>
            <IconButton
              style={{ outline: 'none' }}
              onClick={() => this.handleCloseLoginDialog()}
            >
              <CloseIcon />
            </IconButton>
          </CloseIconWrap>
          <LoginModalContainer>
            <Grid container style={{ display: 'unset' }}>
              <Grid item style={{ display: 'flex' }}>
                <Grid item md={12} style={{ width: '100%' }}>
                  <LeftLoginSection>
                    <h1 className="c:#283583 fs-24 font-bold text-center -mb-15 mt-15 pd-20">{t('login_modal.welcome_back')}</h1>
                    <FormSection>
                      <FormContainer className="pt-20">
                        <TextField
                          id="outlined-adornment-email"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          variant="outlined"
                          type="text"
                          label="Email"
                          value={email}
                          helperText={
                            (isAllowShowErrValidation &&
                              !email &&
                              t('login_modal.msg_empty_email')) ||
                            (isAllowShowErrValidation &&
                              !validateEmailCommon(email) &&
                              t('login_modal.invalid_email')) ||
                            (errors.email &&
                              errors.email.length > 0 &&
                              errors.email[0])
                          }
                          error={
                            (errors.email && errors.email.length > 0) ||
                            (isAllowShowErrValidation &&
                              (!email || !validateEmailCommon(email)))
                          }
                          onChange={this.handleChange('email')}
                        />
                        <TextField
                          id="outlined-adornment-password1"
                          className={classNames(
                            classes.margin,
                            classes.textField,
                          )}
                          variant="outlined"
                          type={showPassword ? 'text' : 'password'}
                          label={t('login_modal.password')}
                          onKeyPress={ev => {
                            if (ev.key === 'Enter') {
                              // Do code here
                              ev.preventDefault();
                              this.handleSubmit();
                            }
                          }}
                          value={password}
                          helperText={
                            (isAllowShowErrValidation &&
                              !password &&
                              t('login_modal.msg_empty_password')) ||
                            (errors.password &&
                              errors.password.length > 0 &&
                              errors.password[0])
                          }
                          error={
                            (errors.password && errors.password.length > 0) ||
                            (isAllowShowErrValidation && !password)
                          }
                          onChange={this.handleChange('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                      <Visibility />
                                    )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {showReverified && (
                          <div
                            className={classes.margin}
                            style={{ color: 'red' }}
                          >
                            <div>{t('login_modal.appears_not_verified')}</div>
                            <div>
                              {t('login_modal.please_click')}{' '}
                              <strong
                                className={classes.link}
                                onClick={this.handleReverified}
                              >
                                {t('login_modal.here')}
                              </strong>{' '}
                              {t('login_modal.to_resend')}
                            </div>
                          </div>
                        )}
                        <RowContainer>
                          <FieldAlignCenter>
                            <Checkbox
                              checked={remember}
                              onChange={this.handleChange('remember')}
                              value="remember"
                            />
                            <div>{t('login_modal.remember_me')}</div>
                          </FieldAlignCenter>

                          <FieldAlignCenter>
                            <Button
                              variant="contained"
                              size="large"
                              color="primary"
                              disabled={loading}
                              className={classes.margin}
                              onClick={this.handleSubmit}
                              style={{ padding: '8px 20px' }}
                            >
                              {t('login')}
                            </Button>
                          </FieldAlignCenter>
                        </RowContainer>

                        <RowContainer>
                          <RegisterNow onClick={handleOpenRegisterModal}>
                            {t('login_modal.register_now')}
                          </RegisterNow>
                          <ForgotPasswordLink onClick={handleOpenForgotModal}>
                            {t('login_modal.forgot_password')}
                          </ForgotPasswordLink>
                        </RowContainer>
                      </FormContainer>
                    </FormSection>
                  </LeftLoginSection>
                </Grid>
                <Grid item md={12}>
                  <RightLoginSection
                    isCandidate={isCandidate}
                  >
                    <div className={classes.landing}>
                      Ready for another exciting day with 9cv9 Partner Program?
                    </div>
                  </RightLoginSection>
                </Grid>
              </Grid>
            </Grid>
          </LoginModalContainer>
        </ModalSection>
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  rememberEmail: PropTypes.string.isRequired,
  resendConfirmation: PropTypes.func.isRequired,
  disableLoading: PropTypes.func.isRequired,
  resetFormLogin: PropTypes.func.isRequired,
  rememberMe: PropTypes.func.isRequired,
  loginWatcher: PropTypes.func.isRequired,
  isCandidate: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleOpenForgotModal: PropTypes.func.isRequired,
  handleOpenRegisterModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingVerify: PropTypes.bool.isRequired,
  showReverified: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles), withTranslation())(LoginModal);
