import React from 'react';
import { withTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatSalary } from 'utils/help';
import './styles.css';

const RenderItem = (props) => {
  const { item, loading, name } = props;
  const {
    view,
    free_trials: freeTrial,
    basic,
    plus,
    premium,
    expired,
  } = item;
  return (
    <tr className="referral-row">
      <td className="referral-row-round">
        <div className="referral-row-item ml-20 mr-20">
          {loading ? <Skeleton width={150} /> : (name || '')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(view) || '')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(freeTrial) || '-')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(basic) || '-')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(plus) || '-')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(premium) || '-')}
        </div>
      </td>
      <td className="referral-header-investor">
        <div className="referral-row-investor">
          {loading ? <Skeleton width={100} /> : (formatSalary(expired) || '-')}
        </div>
      </td>
    </tr>
  );
};

const ReferralBoard = props => {
  const { loading, link, referralCode, t } = props || {};

  return (
    <div className="data-table-referral-container">
      <table className="data-table-referral">
        <tbody>
          <tr className="referral-table-header">
            <th />
            <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.refer_board.views')}</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>Free Trial</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>Basic</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>Plus</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>Premium</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.refer_board.expired')}</span>
              </div>
            </th>
          </tr>
          {link && (<RenderItem item={link} loading={loading} name={t('dashboard_page.refer_board.affiliate_link')} t={t} />)}
          {referralCode &&
            (<RenderItem item={referralCode} loading={loading} name={t('dashboard_page.refer_board.referral_code')} t={t} />)}
        </tbody>
      </table>
    </div>
  );
};

export default withTranslation()(ReferralBoard);
