import React from 'react';
import { LeftSection as LeftSectionContainer } from '../styled';
import customContent from './customContent';

const LeftSection = props => {
  const { isCandidate, modalType } = props;
  const content = customContent[modalType];
  return (
    <LeftSectionContainer
      isCandidate={isCandidate}
      style={content && content.css}
    >
      {content && content.words()}
    </LeftSectionContainer>
  );
};

export default LeftSection;
