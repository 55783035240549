import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginWatcher } from 'slices/login/loginSlice';
import { verifyWatcher } from 'slices/signup/signupSlice';

import VerifyPage from './VerifyPage';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginWatcher,
      verifyWatcher,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  errors: state.login.errors,
  loading: state.login.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyPage);
