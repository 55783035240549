import React from 'react';
import imageRegisterLanding from 'assets/images/register-new.svg';

const textStyles = {
  landing: {
    position: 'absolute',
    textAlign: 'center',
    padding: '0 15px 10px 15px',
    bottom: '0',
    left: 0,
    fontSize: 14,
  },
};

const customContent = {
  landing: {
    words: style => (
      <p style={style || textStyles.landing}>
        <div>Register now and kickstart your Partners career with 9cv9</div>
        <div>We will enable you to succeed in life.</div>
      </p>
    ),
    css: {
      backgroundImage: `url(${imageRegisterLanding})`,
      backgroundPosition: '60% 30%',
      backgroundSize: '80%',
    },
  },
};

export default customContent;
