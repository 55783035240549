import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { Label } from './styled';

const styles = {
  inputWrap: {
    marginTop: 20,
  },
  textField: {
    marginRight: 8,
    width: '100%',
    textAlign: 'left',
  },
};

const OnboardingInput = ({
  label,
  subLabel,
  value,
  inputClassName,
  labelClassName,
  name,
  type,
  onInput,
  placeholder,
  helperText,
  error,
  onChange,
  onBlur,
  classes,
  inputComponent,
  subInputComponent,
  disabled,
  fullWidth,
  multiline,
  rows,
  variant,
  inputProps,
}) => (
    <Col xs={12} lg={fullWidth ? 12 : 6} className={classes.inputWrap}>
      <Row>
        <Col xs={fullWidth ? 12 : 5} className={labelClassName || ''}>
          <Label style={fullWidth ? { textAlign: 'left', marginBottom: 5 } : null}>{label}</Label>
          {subLabel && (
            <div style={{ textAlign: 'right' }}>
              <small>{subLabel}</small>
            </div>
          )}
        </Col>
        <Col xs={fullWidth ? 12 : 7} style={{ textAlign: 'left' }}>
          {inputComponent || (
            <TextField
              className={inputClassName || classes.textField}
              value={value}
              name={name}
              type={type}
              onInput={onInput}
              placeholder={placeholder}
              helperText={helperText}
              error={error}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              multiline={multiline}
              rows={rows}
              variant={variant}
              inputProps={inputProps || { style: { textAlign: 'left' } }}
            />
          )}
          {subInputComponent || null}
        </Col>
      </Row>
    </Col>
  );

export default withStyles(styles)(OnboardingInput);
