import { create } from 'apisauce';

const apiUrl = process.env.REACT_APP_API_HOST;
const urlCountry = 'https://restcountries.eu/rest/v2/all/?fields=name;alpha3Code;alpha2Code';

export const API_ROUTES = {
  JOBS: '/job/',
  SIGNUP: '/rest-auth/registration/',
  LOGIN: '/rest-auth/login/',
  VERIFY: '/rest-auth/registration/verify-email/',
  ONBOARD: '/rest-auth/user/',
  LOGOUT: '/rest-auth/logout/',
  RESENDEMAIL: '/rest-auth/registration/resend-confirmation/',
  DASHBOARD: '/dashboard/',
  CHANGEPASSWORD: '/rest-auth/password/change/',
  FORGOT: '/rest-auth/password/reset/',
  ANALYTIC: '/analytic/',
  AFFILIATE: '/analytic/affiliate-link-view/',
  RESETPASSWORD: '/rest-auth/password/reset/confirm/',
  TNC: '/configuration/tnc/',
};

export const API = create({
  baseURL: apiUrl,
});

export const APIGetCountry = create({
  baseURL: urlCountry,
});
