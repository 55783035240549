// State and the Single Source of Truth
const TOKEN = 'token';
const ONBOARDING = 'onboarding';

/**
 * Location Storage Handler
 */
export const getAuthData = () => {
  const token = localStorage.getItem(TOKEN) || null;
  const data = {
    token,
    onboarding: localStorage.getItem(ONBOARDING),
    isLoggedIn: token !== null,
  };

  return data;
};
