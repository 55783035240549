import React from 'react';
import ShortDescription from './ShortDescription';

const AboutInfo = ({
  company,
  classes,
  profile,
}) => (
    <ShortDescription company={company} classes={classes} profile={profile} />
  );

export default AboutInfo;
