import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employerList: [],
  employerCount: 0,
  isLoading: false,
  analytic: [],
  isLoadingAnalytic: false,
  link: {},
  referralCode: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardWatcher: (state) => {
      state.isLoading = true;
    },
    updatedashboardSuccess: (state, action) => {
      state.isLoading = false;
      state.employerList = action.payload.results;
      state.employerCount = action.payload.count;
    },
    updatedashboardFailed: (state) => {
      state.isLoading = false;
    },
    analyticWatcher: (state) => {
      state.isLoadingAnalytic = true;
    },
    analyticSuccess: (state, action) => {
      state.isLoadingAnalytic = false;
      state.link = action.payload.link;
      state.referralCode = action.payload.referral_code;
    },
    analyticFailed: (state) => {
      state.isLoadingAnalytic = false;
    },
  },
});

export const {
  dashboardWatcher,
  updatedashboardSuccess,
  updatedashboardFailed,
  analyticWatcher,
  analyticSuccess,
  analyticFailed,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
