import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Span } from './styled';

function CenterLineText(props) {
  const { t } = useTranslation();
  return (
    <Container>
      <Span>{t('or')}</Span>
    </Container>
  );
}

export default CenterLineText;
