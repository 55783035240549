import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';

import { tncWatcher, tncSuccess, tncFailed } from './tncSlice';

function* tncEffectSaga(action) {
  const response = yield call(() => API.get(API_ROUTES.TNC, action.payload));
  if (response.ok) {
    yield put(tncSuccess(response.data));
  } else {
    yield put(tncFailed(response.data));
  }
}

function* registrationSaga() {
  yield takeLatest(tncWatcher, tncEffectSaga);
}

export default registrationSaga;
