/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
// import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bool, func } from 'prop-types';

// import { getLoginStatus } from 'store/sagas/loginSaga/selectors';
// import { openRegisterModal } from 'store/sagas/modalsSaga/actions';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/9cv9-logo.svg';
import {
  Footer,
  LogoFooterContainer,
  FirstColumnContent,
  FooterMenuTitle,
  FooterMenuLink,
  SocialSection,
  ContainerIcon,
  ContainerGrid,
  ItemGrid,
} from './styled';

const FooterContain = ({ push }) => {
  // const handleOpenRegisterModal = () => {
  //   const isLoggedIn = localStorage.getItem('token');

  //   if (isLoggedIn) {
  //     window.location.pathname = '/dashboard';
  //   } else {
  //     handleOpenRegisterModalRedux(isCandidate);
  //   }
  // };

  // const isCandidate =
  //   window.location.pathname === '/' ||
  //   window.location.pathname === '/job-lists';
  // const { t } = useTranslation();
  const handleRedirect = url => () => push(url);

  const { t } = useTranslation();

  // const redirectDomain = url => () => window.location.href = url;

  return (
    <Footer className="left-0 bottom-0 min-h-335">
      <ContainerGrid>
        <ItemGrid item xs={12} md={5} lg={3}>
          <FirstColumnContent>
            <LogoFooterContainer onClick={handleRedirect('/')} >
              <img src={logo} alt="logo" width="220" height="170"/>
            </LogoFooterContainer>
          </FirstColumnContent>
        </ItemGrid>
        <ItemGrid item xs={12} md={2} lg={3}>
          <FooterMenuTitle>{t('footer.team')}</FooterMenuTitle>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.aboutus')}</FooterMenuLink>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.pricing')}</FooterMenuLink>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.privacy_policy')}</FooterMenuLink>
        </ItemGrid>
        {/* {isLogin && ( */}
        <ItemGrid item xs={12} md={2} lg={3}>
          <FooterMenuTitle>{t('footer.candidates')}</FooterMenuTitle>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.news')}</FooterMenuLink>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.contact')}</FooterMenuLink>
          <FooterMenuLink onClick={handleRedirect('/')}>{t('footer.tnc')}</FooterMenuLink>
        </ItemGrid>
        {/* )} */}
        <ItemGrid item xs={12} md={3}>
          <SocialSection className="pt-30 pl-30" >
            <ContainerIcon>
              <Link
                href="https://www.facebook.com/9cv9.official/"
                target="_blank"
              >
                <div className="lazy-inline">
                  <FaFacebookF
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </div>
              </Link>
            </ContainerIcon>
            <ContainerIcon>
              <Link href="https://twitter.com/9cv9_official" target="_blank">
                <div className="lazy-inline">
                  <FaTwitter
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </div>
              </Link>
            </ContainerIcon>
            <ContainerIcon>
              <Link
                href="https://www.instagram.com/9cv9.official"
                target="_blank"
              >
                <div className="lazy-inline">
                  <FaInstagram
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </div>
              </Link>
            </ContainerIcon>
            <ContainerIcon>
              <Link href="https://sg.linkedin.com/company/9cv9" target="_blank">
                <div className="lazy-inline">
                  <FaLinkedinIn
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </div>
              </Link>
            </ContainerIcon>
          </SocialSection>
        </ItemGrid>
      </ContainerGrid>
    </Footer>
  );
};

const mapStateToProps = () => ({
  // isLogin: getLoginStatus(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // handleOpenRegisterModalRedux: openRegisterModal,
      push,
    },
    dispatch,
  );
FooterContain.propTypes = {
  isLogin: bool.isRequired,
  push: func.isRequired,
  handleOpenRegisterModalRedux: func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FooterContain);
