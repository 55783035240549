import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { MdPlace } from 'react-icons/md';
import {
  withStyles,
  IconButton,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  Fab,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CommonModal from 'views/Setting/CommonModal';
import { updateProfile } from 'slices/profile/profileSlice';
import { getCountryCode } from 'utils/config';
import LocationAutoComplete from 'components/Common/TextFieldAutocomplete';
import { stylesFormEdit } from './styled';
// import { updateProfileProcessing } from '../../../../../../store/sagas/profileSaga/actions';

const initialState = {
  id: '',
  position: '',
  first_name: '',
  last_name: '',
  area: '',
  mobile_number: '',
  skype: '',
  address: '',
  date_of_birth: null,
  check: 0,
  isOpenDeleteConfirmModal: false,
  firstNameError: '',
  lastNameError: '',
  skypeError: '',
  addressError: '',
};

class FormEditProfile extends PureComponent {
  state = initialState;

  componentDidMount = () => {
    const {
      profile: { first_name: firstName, last_name: lastName, mobile_number: mobileNumber, skype, address },
    } = this.props;

    const split = mobileNumber.split(' ');
    const areaNum = split[0];
    const number = split[split.length - 1];
    const convert = number ? number.replace(/-/g, '') : '';

    const phone = parseInt(convert, 10);
    this.setState({
      first_name: firstName,
      last_name: lastName,
      area: areaNum,
      mobile_number: phone,
      skype,
      address,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isRequesting, onCloseModal } = this.props;
    if (isRequesting && !nextProps.isRequesting) {
      onCloseModal();
    }
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  validate = () => {
    const { t } = this.props;
    const { first_name, last_name, skype, address } = this.state;
    let firstNameError = '';
    let lastNameError = '';
    let skypeError = '';
    let addressError = '';
    let check = true;

    if (first_name.length > 100) {
      firstNameError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (last_name.length > 100) {
      lastNameError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (skype.length > 100) {
      skypeError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    if (address.length > 100) {
      addressError = t('profile.header.invalid_role').replace('xxx', '100');
      check = false;
    }

    const updateState = {
      firstNameError,
      lastNameError,
      skypeError,
      addressError,
    };
    this.setState(updateState);

    return check;
  };

  isDisableSubmit = () => {
    const { first_name: firstName, last_name: lastName, mobile_number: mobileNumber } = this.state;
    if (!firstName || !lastName || !mobileNumber) {
      return true;
    }
    return false;
  };

  handleSubmit = () => {
    const { updateProfile } = this.props;
    const {
      first_name: firstName,
      last_name: lastName,
      area,
      mobile_number: mobileNumber,
      skype,
      address,
    } = this.state;
    if (this.validate()) {
      const params = {
        first_name: firstName,
        last_name: lastName,
        mobile_number: `${area}  ${mobileNumber}`,
        skype: skype || '',
        address,
      };
      updateProfile(params);
    }
  };

  handleChangeAddress = (address) => {
    this.setState({ address: address || '' });
  };

  handleSelect = address => {
    this.setState({ address });
  };

  render() {
    const isDisableSubmit = this.isDisableSubmit();
    const {
      onCloseModal,
      classes,
      isRequesting,
      isOpenModal,
      t,
    } = this.props;
    const {
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      address,
      area,
      skype,
      firstNameError,
      lastNameError,
      skypeError,
      addressError,
    } = this.state;

    return (
      <CommonModal onCloseModal={onCloseModal} isOpenModal={isOpenModal}>
        <div className={classes.paper}>
          <Modal open={isRequesting} disableBackdropClick disableEscapeKeyDown>
            <div className="flex items-center justify-center h-full c:black">
              <CircularProgress color="inherit" />
              <Typography variant="h6" id="modal-title">
                {t('profile.header.edit_profile')}
              </Typography>
            </div>
          </Modal>
          <div className="lg:w-700 max-h-600 overflow-auto max-w-1050">
            <div className={classes.modalHeader}>
              <h1 className="c:black fs-20 font-bold text-center -mb-15 mt-15">{t('profile.header.edit_profile')}</h1>
              <IconButton style={{ outline: 'none' }} className={classes.btnClose} onClick={onCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modal-body">
              <div className={classes.conts}>
                {/* First Name */}
                <TextField
                  fullWidth
                  id="first_name"
                  label={t('onboarding.basic_info.first_name')}
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.inputSize } }}
                  value={firstName}
                  onChange={this.handleChange('first_name')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={!!(firstNameError !== '')}
                  helperText={firstNameError}
                />
                {/* Last Name */}
                <TextField
                  fullWidth
                  id="last_name"
                  label={t('onboarding.basic_info.last_name')}
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.inputSize } }}
                  value={lastName}
                  onChange={this.handleChange('last_name')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={lastNameError}
                  helperText={lastNameError}
                />
              </div>
              <div className={classes.conts}>
                <div className={classes.dateField}>
                  {/* Mobile Number */}
                  <TextField
                    select
                    fullWidth
                    id="area"
                    label={t('profile.header.country')}
                    placeholder="+62"
                    className={classes.area}
                    value={area}
                    onChange={this.handleChange('area')}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: classes.inputSizeNumber,
                    }}
                  >
                    {getCountryCode().map(name => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id="mobile_number"
                    label={t('onboarding.basic_info.mobile_number')}
                    type="number"
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 12);
                    }}
                    className={classes.phone}
                    InputProps={{
                      classes: { input: classes.inputSize },
                    }}
                    value={mobileNumber}
                    onChange={this.handleChange('mobile_number')}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* Skype */}
                <TextField
                  fullWidth
                  id="skype"
                  label="Skype"
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.inputSize } }}
                  value={skype}
                  onChange={this.handleChange('skype')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={skypeError}
                  helperText={skypeError}
                />
              </div>
              <div>
                {/* Address */}
                <LocationAutoComplete
                  isFullWidth
                  value={address}
                  label={t('profile.header.address')}
                  id="address"
                  error={false}
                  icon={<MdPlace style={{ paddingRight: 10, fontSize: 30 }} />}
                  classInputSize={classes.inputSize}
                  onChange={this.handleChangeAddress}
                  onSelect={this.handleSelect}
                  styleWrapperItem={{
                    width: '100%',
                  }}
                  helperText={addressError}
                />
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Fab
                variant="extended"
                size="small"
                aria-label="Save"
                style={{
                  width: 150,
                  height: 40,
                  borderRadius: 30,
                  margin: 10,
                  backgroundColor: '#bcd9f9',
                  outline: 'none',
                }}
                disabled={isDisableSubmit}
                onClick={this.handleSubmit}
              >
                {t('profile.header.save')}
              </Fab>
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

FormEditProfile.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  // updateProfileProcessing: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
};

FormEditProfile.defaultProps = {
  isRequesting: false,
};

const mapDispatchToProps = dispatch => ({
  updateProfile: bindActionCreators(updateProfile, dispatch),
});

const mapStateToProps = state => ({
  isRequesting: state.profile.isLoadingUpdate,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(stylesFormEdit),
)(FormEditProfile);
