import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  errors: {},
  email: '',
  isLoadingReset: false,
  errorsReset: {},
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signupWatcher: (state) => {
      state.isLoading = true;
    },
    signupSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    signupFailed: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    verifyWatcher: () => { },
    forgotWatcher: () => { },
    forgotSuccess: (state, action) => {
      state.email = action.payload;
    },
    resetPasswordWatcher: (state) => {
      state.isLoadingReset = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.isLoadingReset = false;
      state.data = action.payload;
    },
    resetPasswordFailed: (state, action) => {
      state.isLoadingReset = false;
      state.errorsReset = action.payload;
    },
  },
});

export const {
  signupWatcher,
  signupSuccess,
  signupFailed,
  verifyWatcher,
  forgotWatcher,
  forgotSuccess,
  resetPasswordWatcher,
  resetPasswordSuccess,
  resetPasswordFailed,
} = signupSlice.actions;

export default signupSlice.reducer;
