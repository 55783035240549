import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './languages/en.json';
import vi from './languages/vi.json';
import id from './languages/id.json';

const resources = {
  en: { translation: en },
  vi: { translation: vi },
  id: { translation: id },
};

if (window.location.href.includes('9cv9.vn')) {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    lng: 'vi',
    fallbackLng: 'en',
  });
} else if (window.location.href.includes('9cv9jobs.id')) {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    lng: 'id',
    fallbackLng: 'en',
  });
} else {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    fallbackLng: 'en',
  });
}

export default i18n;

export const t = (param) =>
  i18n.t(param, {
    locale: i18n.language || localStorage.i18nextLng || 'en',
  });
