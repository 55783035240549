import styled from 'styled-components';
import imageRegister from 'assets/images/pexels-photo.jpg';
import imageRegisterCandidate from 'assets/images/Register.png';
import imageForgot1Candidate from 'assets/images/ForgotPassword1.png';
import imageForgot2Candidate from 'assets/images/ForgotPassword2.png';
import imageForgot from 'assets/images/art-drawing-eye-948620.jpg';
import imageRegisterLanding from 'assets/images/register-new.svg';

export const ModalSection = styled.div`
  max-height: 100%;
  max-width: 800px;
  @media only screen and (max-width: 768px) {
    max-width: 400px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  max-width: 100%;
  min-height: 500px;
  @media only screen and (max-width: 1280px) {
    max-height: 616px;
  }
  @media only screen and (max-width: 768px) {
    max-height: 600px;
  }
`;

export const LoginModalContainer = styled.div`
  display: flex;
  max-width: 100%;
`;

export const ModalReviewContainer = styled.div`
  display: flex;
  width: 700px;
  max-height: 90vh;
  overflow: auto;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 500px;
  }
  @media only screen and (max-width: 500px) {
    width: 90vw;
  }
`;

export const ModalTestimonialContainer = styled.div`
  display: flex;
  max-height: 90vh;
  overflow: auto;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 500px;
  }
  @media only screen and (max-width: 500px) {
    width: 90vw;
  }
`;

export const ReviewTitle = styled.h1`
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  margin-top: -5px;
`;
export const ModalTitle = styled.h1`
  color: #283583;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

export const ReviewContainer = styled.div`
  width: 600px;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
`;
export const FormContainer = styled.div`
  width: 318px;
`;

export const TableReview = styled.table`
  margin-top: 0px;
  width: 100%;
  font-size: 14px;
  text-align: center;

  td {
    padding-left: 18px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 100px;
  }
  tr {
    padding-top: 0.2rem;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FieldAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 960px) {
    ${({ onlyMobile }) => onlyMobile && 'display: none;'}
  }
`;

export const LeftForgotSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem!important;
  padding-top: 3rem!important;
  @media only screen and (max-width: 768px) {
    min-height: 475px;
  }
`;

export const LeftLoginSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem!important;
  padding-top: 3rem!important;
`;

export const RightLoginSection = styled.div`
  flex: 1;
  height: 100%;
  min-height: 450px;
  background-image: url(${imageRegisterLanding});
  background-repeat: no-repeat;
  background-color: #FFF5F5;
  background-position: 60% 30%;
  background-size: 80%;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
    margin-left: -10px;
  }
`;

export const CloseLoginContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RegisterNow = styled.div`
  color: #5d8ffc;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 14px;
  cursor: pointer;
`;

export const ForgotPasswordLink = styled.div`
  color: #929699;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: right;
  padding-right: 8px;
  cursor: pointer;
`;

export const SocialButtonContainer = styled.a`
  margin-top: ${props => props.marginTop}px;
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  color: #fff !important;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  transition: 0.3s ease-out;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  height: 52px;
  width: 100%;
  line-height: 52px;
  text-transform: uppercase;
`;

export const SocialButtonClaimContainer = styled.a`
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  margin-right: ${props => (props.marginRight ? `${props.marginRight}px` : '0px')};
  margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '0px')};
  color: #fff !important;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  transition: 0.3s ease-out;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  height: 45px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
`;

export const IconContainer = styled.i`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 1rem;
  margin-right: 2rem;
`;

export const IconClaimContainer = styled.i`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 1rem;
  margin: auto;
`;

export const SocialButtonSection = styled.div`
  margin-top: 15px;
  padding: 0 10px;
`;

export const LeftSection = styled.div`
  flex: 1;
  height: 100%;
  min-height: 400px;
  background-image: url(${props =>
    (props.isCandidate ? imageRegisterCandidate : imageRegister)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #F4F2FF;
  position: relative;
  @media only screen and (max-width: 959px) {
    p {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const RightForgotSection = styled.div`
  flex: 1;
  background-image: url(${props =>
    (props.isCandidate ? imageForgot1Candidate : imageForgot)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f5fff5;
  @media only screen and (max-width: 768px) {
    display: none;
    margin-left: -10px;
  }
`;

export const RightForgotConfirmSection = styled.div`
  flex: 1;
  background-image: url(${props =>
    (props.isCandidate ? imageForgot2Candidate : imageForgot)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f5fff5;
  @media only screen and (max-width: 768px) {
    display: none;
    margin-left: -10px;
  }
`;

export const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem!important;
  padding-top: 1.5rem!important;
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FieldAlignCenterCheckbox = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const TextEmail = styled.div`
  color: #929699;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  padding-bottom: 30px;
`;

export const ThanksForSection = styled.div`
  font-size: 20px;
  color: rgba(145, 150, 152, 1);
  text-align: center;
  line-height: 27px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

export const ModalCreditCardContainer = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  min-height: 500px;
  flex-direction: column;
`;

export const TopAddCreditCard = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseIconWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
`;

export const FormAddCreditContainer = styled.div`
  width: 100%;
  padding: 0 70px;
`;

export const DefaultCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalCreditTitle = styled.h1`
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: -5px;
`;
export const HeaderReview = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 44px;
  padding: 20px 30px;
  border-bottom: 1px solid #aeaeaf;
  color: #2f2e41;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TitleReview = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #2f2e41;
  margin-top: 20px;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ReviewBody = styled.div`
  padding: 30px;
`;

export const TestimonialBody = styled.div`
  padding: 30px;
  @media only screen and (min-width: 769px) {
    display: flex;
  }
`;

export const TestimonialBodyLeft = styled.div`
  @media only screen and (min-width: 769px) {
    width: 500px;
  }
`;

export const TestimonialBodyRight = styled.div`
  width: 300px;
  margin-top: 20px;
  @media only screen and (min-width: 769px) {
    margin-left: 30px;
  }
`;

export const RateTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  margin-top: 20px;
  color: #2f2e41;
  flex: 0 0 50%;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ReviewLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.05em;
  margin-right: 20px;
  color: #2f2e41;
  flex: 0 0 50%;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RatingItem = styled.div`
  @media only screen and (min-width: 769px) {
    display: flex;
  }
  padding: 10px 0;
`;

export const ReviewBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px 20px;
`;

export const TestimonialBottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 30px;
  border-top: 1px solid #aeaeaf;
`;

export const ErrorRate = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 27px;
  margin-top: 10px;
  color: red;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ResultFindRowCompany = styled.div`
  line-height: 38px;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: #dddd;
  }
`;

export const PersonalityResultContainer = styled.div`
  font-size: 1rem;
  @media (max-width: 780px) {
    font-size: 0.9rem;
  }
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
  .modal-title {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: 600;
  }
  .bold {
    font-weight: bold;
  }
  .red {
    color: #FF4600;
  }
`;

export const PersonalityButton = styled.button`
  background: #EB5B49;
  box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.25)!important;
  border-radius: 100px;
  height: 2.5em;
  color: white;
  padding: 0.5em 2em;
  margin: 0.5rem;
  @media (max-width: 365px) {
    font-size: 0.8em;
  }
  svg {
    height: 100%;
    width: auto;
    margin-right: 0.5em;
    color: white;
  }
`;
