import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { requestNotifications } from 'store/sagas/profileSaga/actions';
// import { getProfile } from 'store/sagas/profileSaga/selectors';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { NavDropdown } from 'react-bootstrap';
import { tncWatcher } from 'slices/tnc/tncSlice';
import ukLogo from 'assets/images/UK_language.svg';
import vietLogo from 'assets/images/Vietnam_SVG.svg';
import indoLogo from 'assets/images/Indonesian_SVG.svg';
import i18n from 'configs/i18n';
import './styles.css';

const LANGUAGES = t => ({
  en: {
    label: t('english'),
    alt: 'UK Logo',
    logo: ukLogo,
    lang: 'en',
  },
  vi: {
    label: t('vietnamese'),
    alt: 'VN Logo',
    logo: vietLogo,
    lang: 'vi',
  },
  id: {
    label: t('indonesian'),
    alt: 'ID Logo',
    logo: indoLogo,
    lang: 'id',
  },
});

const DropDown = props => {
  const { t } = useTranslation();
  let languageDetected = _get(i18n, 'language') || window.localStorage.i18nextLng || 'en';

  if (languageDetected.includes('en')) languageDetected = 'en';
  if (languageDetected.includes('vi')) languageDetected = 'vi';
  if (languageDetected.includes('id')) languageDetected = 'id';

  const handleClickChangeLanguage = (item) => {
    const { tncWatcher } = props;
    if (item) {
      i18n.changeLanguage(item.lang);
      tncWatcher({ lang: item.lang });
    }
  };

  const curLang = LANGUAGES(t)[languageDetected] || LANGUAGES(t).en;
  const title = (
    <>
      <div className="inline-block items-center">
        <img
          src={curLang.logo}
          alt={curLang.alt}
          className="country-logo"
          style={{ borderRadius: '50%' }}
        />
      </div>
      &nbsp;
      {curLang.label}
    </>
  );

  return (
    <NavDropdown
      style={{ marginLeft: 10 }}
      title={title}
      id="basic-nav-dropdown"
    >
      {Object.values(LANGUAGES(t)).map(item => (
        <NavDropdown.Item
          onClick={() => handleClickChangeLanguage(item)}
          key={item && item.label}
        >
          <div className="flex">
            <div className="inline-block items-center">
              <img
                src={item && item.logo}
                alt={item && item.alt}
                className="country-logo"
                style={{ borderRadius: '50%' }}
              />
            </div>
            &nbsp;
            {item && item.label}
          </div>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // requestNotifications,
      tncWatcher,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  // profile: getProfile(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropDown);
