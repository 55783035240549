import React from 'react';
import { withTranslation } from 'react-i18next';
import momment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
// import MenuAction from './menuAction';
import './styles.css';

const EmployerList = props => {
  const { data, loading, t } = props || {};

  return (
    <div className="data-table-partner-container">
      <table className="data-table-partner">
        <tbody>
          <tr className="partner-table-header">
            <th>
              <div className="header-column-content">
                <span className="partner-header-round">{t('dashboard_page.employer_list.employer')}</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.employer_list.date')}</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.employer_list.company')}</span>
              </div>
            </th>
            <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.employer_list.status')}</span>
              </div>
            </th>
            {/* <th>
              <div className="header-column-content">
                <span>{t('dashboard_page.employer_list.actions')}</span>
              </div>
            </th> */}
          </tr>
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              const {
                employer,
                date_joined,
                company,
                status,
              } = item;
              return (
                <tr key={`Trf1k-${index}`} className="partner-row">
                  <td className="partner-row-round">
                    <div className="partner-row-item ml-20 mr-20">
                      {loading ? <Skeleton width={150} /> : (employer || '-')}
                    </div>
                  </td>
                  <td className="partner-row-round-status">
                    <div className="partner-row-item">
                      {loading ? <Skeleton width={150} /> : (date_joined ? momment(date_joined).format('DD/MM/YY') : '-')}
                    </div>
                  </td>
                  <td className="partner-row-investor">
                    <div className="partner-row-item">
                      {loading ? <Skeleton width={150} /> : (company || 'No Company')}
                    </div>
                  </td>
                  <td className="partner-header-investor">
                    <div className="partner-row-investor">
                      {loading ? <Skeleton width={150} /> : (status || '-')}
                    </div>
                  </td>
                  {/* <td className="partner-header-investor">
                    <div className="partner-row-investor">
                      {loading ? <Skeleton width={150} /> : (
                        <MenuAction
                          allow_unpublish
                          is_published
                          index={index}
                          t={t}
                        />
                      )}
                    </div>
                  </td> */}
                </tr>
              );
            })}
          {(data && data.length === 0) && (
            <tr>
              <td colSpan={4}>
                <div className="mt-20 mb-20 text-center">
                  {t('dashboard_page.empty_data')}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default withTranslation()(EmployerList);
