import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    left: '-38px',
    right: '-38px',
  },
});

const CloseButton = styled.div`
  padding-right: 10px;
  padding-top: 3px;
  text-align: right;
  width: 100%;
  cursor: pointer;

  &:after {
    content: '✖';
  }
`;
const Popup = props => {
  const { show, content, maxWidth, fullWidth, onClose, classes, noPadding } = props;

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth} // ["xs","sm","md","lg","xl",false]
      fullWidth={fullWidth}
      className={classes.root}
    >
      <DialogTitle style={{ position: noPadding ? 'absolute' : 'initial', top: 0, right: 0, zIndex: 100, padding: 0 }}>
        <CloseButton onClick={() => onClose()} />
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center', fontSize: 18, padding: noPadding ? 0 : null }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles)(Popup);
