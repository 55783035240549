// Media query following bootstrap
import styled from 'styled-components';
// import { EllipsisText } from 'utils/styled';

export const GeneralInfoWrap = styled.div`
  background-color: white;
`;

export const AvatarWrap = styled.div`
  height: 100px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  img {
    border-radius: 50%;
    border: 1px solid rgb(218, 220, 224);
    height: 100%;
    width: 100%;
    object-fit: ${({ fitCover }) => (fitCover ? 'cover' : 'contain')};
  }

  @media only screen and (min-width: 992px) {
    height: 150px;
    width: 150px;
  }

  @media only screen and (min-width: 1200px) {
    height: 180px;
    width: 180px;
  }
`;

export const AvatarWrapSmall = styled.div`
  height: 100px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  img {
    border-radius: 50%;
    border: 1px solid rgb(218, 220, 224);
    height: 100%;
    width: 100%;
    object-fit: ${({ fitCover }) => (fitCover ? 'cover' : 'contain')};
  }

  @media only screen and (min-width: 992px) {
    height: 120px;
    width: 120px;
  }

  @media only screen and (min-width: 1200px) {
    height: 150px;
    width: 150px;
  }
`;

export const Title = styled.div`
  display: inline-block;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 0;
  max-width: calc(100% - 48px);

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 36px;
    max-width: calc(100vw - 642px);
    padding: 12px 0;
  }

  @media only screen and (min-width: 1200px) {
    max-width: calc(100vw - 666px);
  }
`;

export const SocialList = styled.div`
  min-width: ${props => (props.socials ? `${props.socials * 32}px` : null)};

  svg {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-right: 6px;

    @media only screen and (min-width: 992px) {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: 8px;
      min-width: 16px;
    }
  }
`;

export const ProfileLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: #707070;
  margin-top: 10px;
  margin-bottom: 0;
  @media only screen and (min-width: 1200px) {
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

export const DesktopProfileLabel = styled(ProfileLabel)`
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const ProfileInfo = styled.div`
  color: #707070;
  margin: 6px -10px 0 0;
  font-size: 12px;

  @media only screen and (min-width: 992px) {
    color: #2F2E41;
    margin: 0;
    font-size: 14px;
  }
`;

export const TextCTA = styled.div`
  color: #303E9F;
  font-weight: 600;
  font-size: 12px;

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;

export const SmallDesc = styled.div`
  font-size: 10px;
  font-style: italic;
`;

export const Icon = styled.span`
  margin-right: 5px;
  display: inline-block;
  line-height: 14px;
  svg {
    height: 14px;
    width: 14px;
    margin-top: -2px;
  }
`;

export const MobileIcon = styled(Icon)`
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export const ChangePasswordBtn = styled.button`
  font-size: 12px;
  display: block;
  border: 1px solid #303E9F;
  border-radius: 6px;
  padding: 5px 12px;
  color: #303E9F;
  margin: 10px auto;
  background-color: white;

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;

export const ShareProfileChannelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  > svg {
    width: 30px;
    height: auto;
    margin: 0.5rem;
    cursor: pointer;
  }
  button {
    margin: 0.5rem;
  }
`;
