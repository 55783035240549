import { API_ROUTES, API, APIGetCountry } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { toastConfig } from 'utils/config';

import {
  profileWatcher,
  profileSuccess,
  profileFailed,
  updateProfile,
  updateSuccess,
  updateFailed,
  changePassword,
  changePasswordSuccess,
} from './profileSlice';

function* profileEffectSaga() {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.get(API_ROUTES.ONBOARD, {}, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(profileSuccess(response.data));
  } else {
    if (response.status === 401) {
      toast.error('Your session has ended, please re-authenticate', toastConfig);
      setTimeout(() => {
        localStorage.clear();
        window.location.replace('/');
      }, 3000);
    }
    yield put(profileFailed(response.data));
  }
}

function* updateProfileEffectSaga(action) {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.patch(API_ROUTES.ONBOARD, action.payload, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(updateSuccess(response.data));
  } else {
    yield put(updateFailed(response.data));
  }
}

function* changePasswordEffectSaga(action) {
  const token = localStorage.getItem('token');
  const response = yield call(() => API.post(API_ROUTES.CHANGEPASSWORD, action.payload, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  if (response.ok) {
    yield put(changePasswordSuccess());
    toast.success('New password has been saved.', toastConfig);
  } else {
    if (response && response.data && response.data.old_password) {
      toast.error(response.data.old_password[0], toastConfig);
    } else {
      toast.error('Can not edit password. Try again !', toastConfig);
    }
  }
}

export function getCountryAPI() {
  const dataResponse = APIGetCountry.get('').then(response => { return response.data });
  return dataResponse;
}

function* registrationSaga() {
  yield takeLatest(profileWatcher, profileEffectSaga);
  yield takeLatest(updateProfile, updateProfileEffectSaga);
  yield takeLatest(changePassword, changePasswordEffectSaga);
}

export default registrationSaga;
