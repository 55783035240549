import React from 'react';
import { Modal, withStyles, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  ModalTitle,
  LeftLoginSection,
  ThanksForSection,
  RightForgotConfirmSection,
  LoginModalContainer,
  CloseIconWrap,
} from '../styled';

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
    maxWidth: '1100px',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 300,
    width: 300,
  },
});

const ForgotConfirmModal = props => {
  const { open, handleClose, classes, email, forgotWatcher } = props;
  const resend = () => {
    forgotWatcher(email);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className={classes.paper}>
        <CloseIconWrap>
          <IconButton
            style={{ outline: 'none' }}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </CloseIconWrap>
        <LoginModalContainer>
          <Grid container style={{ display: 'unset' }}>
            <Grid item style={{ display: 'flex' }}>
              <Grid item md={12} style={{ width: '100%' }}>
                <LeftLoginSection>
                  <ModalTitle style={{ marginTop: 50 }}>
                    Forgot Password?
                    </ModalTitle>

                  <ThanksForSection>
                    <div style={{ width: 399 }}>
                      <span>
                        We have sent a link to your email to reset the password.
                        <br />
                        <br />
                          Please check your email inbox.
                        <br />
                        <br />
                          Didn’t receive it? We can{' '}
                        <span
                          style={{
                            color: 'rgba(0,105,176,1.0)',
                            cursor: 'pointer',
                          }}
                          aria-hidden
                          onClick={resend}
                        >
                          resend it
                        </span>
                      </span>
                    </div>
                  </ThanksForSection>
                </LeftLoginSection>
              </Grid>
              <Grid item md={12}>
                <RightForgotConfirmSection style={{ minHeight: '500px' }} />
              </Grid>
            </Grid>
          </Grid>
        </LoginModalContainer>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ForgotConfirmModal);
