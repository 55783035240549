/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { withStyles, IconButton, Typography, Button, Modal, Fab, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import { updateProfile } from 'slices/profile/profileSlice';
import styles from './styled';
import { toastConfig } from '../../../../utils/config';

class FormAddSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRequesting: false,
      avatar: null,
      imagePreviewUrl: '',
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isRequesting, onCloseModal } = this.props;
    if (isRequesting && !nextProps.isRequesting) {
      onCloseModal();
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { updateProfile, onCloseModal } = this.props;
    const { imagePreviewUrl } = this.state;
    const params = {
      avatar: imagePreviewUrl,
    };
    updateProfile(params);
  }

  onChange(e) {
    e.preventDefault();
    const { t } = this.props;
    if (e.target.files[0]) {
      if (e.target.files[0].size > 2000000) {
        toast.error(t('profile.avatar.max_size_upload'), toastConfig);
      } else {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            avatar: file,
            imagePreviewUrl: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    }
  }

  isDisableSubmit = () => {
    const { avatar } = this.state;
    if (!avatar) {
      return true;
    }
    return false;
  };

  render() {
    const isDisableSubmit = this.isDisableSubmit();
    const { onCloseModal, classes, isRequesting, t } = this.props;
    const { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          src={imagePreviewUrl}
          alt="Preview_Image"
          style={{
            width: '50%',
            height: '50%',
            marginTop: 7,
            marginBottom: 15,
          }}
        />
      );
    } else {
      $imagePreview = (
        <div className="previewTest" style={{ marginTop: 50, marginBottom: 60 }}>
          {t('profile.avatar.ask_select_img')}
        </div>
      );
    }

    return (
      <div className={classes.paper}>
        <Modal open={isRequesting} disableBackdropClick disableEscapeKeyDown>
          <div className="flex items-center justify-center h-full c:black">
            <CircularProgress color="inherit" />
            <Typography variant="h6" id="modal-title">
              {t('profile.avatar.uploading_avatar')}
            </Typography>
          </div>
        </Modal>

        <div className="w-700 max-h-600 overflow-auto max-w-1050 text-center">
          <div className={classes.modalHeader}>
            <h1 className="c:black fs-20 font-bold -mb-15 mt-15">{t('profile.avatar.upload_avatar')}</h1>
            <IconButton style={{ outline: 'none' }} className={classes.btnClose} onClick={onCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <br />
          <div className="modal-body">
            <Button
              variant="contained"
              size="small"
              color="default"
              component="label"
              className={classes.button}
              style={{
                backgroundColor: '#FFE7E7',
              }}
            >
              <AddPhotoAlternate className={classes.rightIcon} />
              &nbsp;
              {t('profile.avatar.click_to_upload')}
              <input type="file" onChange={this.onChange} style={{ display: 'none' }} accept=".jpg , .jpeg , .png" />
            </Button>
          </div>
          {$imagePreview}
          <div className={classes.modalFooter}>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="Save"
              style={{
                width: 290,
                height: 45,
                borderRadius: 30,
                marginBottom: -20,
              }}
              disabled={isDisableSubmit}
              onClick={this.onFormSubmit}
            >
              {t('profile.avatar.save')}
            </Fab>
          </div>
        </div>
      </div>
    );
  }
}

FormAddSummary.propTypes = {
  classes: PropTypes.any.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isRequesting: state.profile.isLoadingUpdate,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    updateProfile,
  }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(FormAddSummary);
