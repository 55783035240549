import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { toastConfig } from 'utils/config';
import {
  Modal,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  ModalSection,
  CloseIconWrap,
} from './styled';

const ReferModal = props => {
  const { open, handleClose, code, t } = props;

  const closeModal = () => {
    handleClose();
  };

  const link = `${process.env.REACT_APP_ROOT_URL}/employer?partnerId=${code}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    toast.success('Copied!', toastConfig);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}
      className="flex justify-center items-center"
    >
      <ModalSection className="absolute bg-c:white p-0 w-full box-shadow-5">
        <CloseIconWrap>
          <IconButton
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </CloseIconWrap>
        <div className="text-center">
          <div className="text-center fs-14 mt-15 mb-15 ml-30 mr-30 line-height-40">
            {t('dashboard_page.refer_1')}
          </div>
          <div className="mb-25">
            <div className="text-center fs-14 m-15">
              {t('dashboard_page.refer_2')}
            </div>
            <div className="flex justify-center">
              <span
                style={{
                  border: '1px solid',
                  padding: '5px 20px',
                  cursor: 'pointer',
                }}
                onClick={handleCopyLink}
              >
                {link}
              </span>
            </div>
          </div>
        </div>
      </ModalSection>
    </Modal>
  );
};

export default withTranslation()(ReferModal);
