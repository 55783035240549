import { push } from 'connected-react-router';
import { API_ROUTES, API } from 'services/apis';
import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { toastConfig } from 'utils/config';

import {
  loginWatcher,
  loginSuccess,
  loginFailed,
  logout,
  resendConfirmation,
  resendConfirmationSuccess,
} from './loginSlice';

function* loginEffectSaga(action) {
  const response = yield call(() => API.post(API_ROUTES.LOGIN, action.payload));
  if (response.ok) {
    const { data } = response;
    yield put(loginSuccess(data));
    localStorage.setItem('token', data.token);
    localStorage.setItem('onboarding', data.user.onboarding);
    if (data.user.onboarding) {
      yield put(push('/dashboard'));
      toast.success('Welcome to 9cv9 Partners Program', toastConfig);
    } else {
      yield put(push('/onboarding'));
    }
  } else {
    yield put(loginFailed(response.data));
    if (
      response.data &&
      response.data.non_field_errors &&
      response.data.non_field_errors[0]
    ) {
      toast.error(response.data.non_field_errors[0], toastConfig);
    }
  }
}

function* logoutSaga() {
  const token = localStorage.getItem('token');
  yield call(() => API.post(API_ROUTES.LOGOUT, {}, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
}

function* resendConfirmationSaga(action) {
  try {
    yield call(() => API.post(API_ROUTES.RESENDEMAIL, { email: action.payload }));
    yield put(resendConfirmationSuccess());
    toast.success('Resend Confirmation through email success, please check your email', toastConfig);
  } catch (error) {
    yield put(resendConfirmationSuccess());
    toast.error('Resend Confirmation Failure!', toastConfig);
  }
}

function* registrationSaga() {
  yield takeLatest(loginWatcher, loginEffectSaga);
  yield takeLatest(logout, logoutSaga);
  yield takeLatest(resendConfirmation, resendConfirmationSaga);
}

export default registrationSaga;
